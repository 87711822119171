import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Switch from "@material-ui/core/Switch";
import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import {
  changeCurrencyFE,
  changeLanguageFE
  // fetchCurrencyFunction
} from "../../../../../../actions/miniCartActions";
import { changeParametrizacoes, getUserInformacoes } from "../../../../../../actions/myihtpActions";
import IHTPButton from "../../../../../Common/IHTPButton";


let container;

class OutrasInformacoes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parametrizacoes: {
        id_moeda: "",
        id_lingua: ""
      },
      TipoInformacao: "Parametrizacoes",
      languages: [],
      currencies: [],
      clientes: false,
      alertas: false
    };

    this.handleInput = this.handleInput.bind(this);
  }
  componentDidMount() {

    getUserInformacoes()
      .then(data => {
        this.setState({ parametrizacoes: data.data });
        if (data.data.gerirclientes === "S") {
          this.setState({ clientes: true });
        }
        if (data.data.alertas === "S") {
          this.setState({ alertas: true });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        parametrizacoes: {
          ...prevState.parametrizacoes,
          [name]: value
        }
      })
    );
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  guardarParam = async e => {
    e.stopPropagation();
    changeParametrizacoes(this.state.parametrizacoes, this.state.alertas, this.state.clientes, this.state.TipoInformacao)
      .then(response => {
        if (response.success === true) {
          getUserInformacoes()
            .then(data => {
              this.setState({ informacao: data.data });
              this.props.dispatch(changeCurrencyFE(this.state.parametrizacoes.id_moeda));
              this.props.dispatch(changeLanguageFE(this.state.parametrizacoes.id_lingua));
            })
            .catch(error => {
              console.log(error);
            });
          container.clear();
          container.success(response.message);
        } else {
          container.clear();
          container.error(response.message);
        }
      });
  };

  render() {
    const { currencies, languages } = this.props;

    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <Row style={{ textAlign: "left" }}>
              <Col xs="12" sm="12" md="12" lg="12">
                <div className="form-group">
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label className="boldMyIHTP">
                        {" "}
                        <FormattedMessage id="myihtp.infopessoal.parametrizacoes.alertas" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Switch
                        checked={this.state.alertas}
                        onChange={this.handleChange("alertas")}
                        value={"alertas"}
                      />
                      {/* <input
                        type="checkbox"
                        name="alertas"
                        id="alertas"
                        value="on"
                        onChange={this.handleInput} 
                      />*/}
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label className="boldMyIHTP">
                        {" "}
                        <FormattedMessage id="myihtp.infopessoal.parametrizacoes.disponivelParaClientes" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Switch
                        checked={this.state.clientes}
                        onChange={this.handleChange("clientes")}
                        value={this.state.clientes}
                      />
                      {/* <input
                        type="checkbox"
                        name="gerirclientes"
                        id="gerirclientes"
                        // value="S"
                        onChange={this.handleInput}
                        defaultChecked={
                          this.state.parametrizacoes.gerirclientes
                        }
                      /> */}
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label className="boldMyIHTP">
                        {" "}
                        <FormattedMessage id="myihtp.infopessoal.parametrizacoes.lingua" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <FormControl
                        style={{ width: "100%", "margin-top": "10px" }}
                      >
                        <NativeSelect
                          name={"id_lingua"}
                          onChange={this.handleInput}
                          value={this.state.parametrizacoes.id_lingua}
                        >
                          <option value="" disabled>
                            {this.props.intl.formatMessage({
                              id:
                                "myihtp.infopessoal.parametrizacoes.linguaMessage"
                            })}
                          </option>
                          {languages.list
                            ? languages.list.map((language, j) => {
                              return (
                                <option value={language.ID_Lingua}>
                                  {language.Descricao}
                                </option>
                              );
                            })
                            : null}
                        </NativeSelect>
                        {/* {this.state.languages.length > 0 ? (
                          
                        ) : null} */}
                      </FormControl>
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label className="boldMyIHTP">
                        {" "}
                        <FormattedMessage id="myihtp.infopessoal.parametrizacoes.moeda" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <FormControl
                        style={{ width: "100%", "margin-top": "10px" }}
                      >
                        <NativeSelect
                          name={"id_moeda"}
                          onChange={this.handleInput}
                          value={this.state.parametrizacoes.id_moeda}
                        >
                          <option value="" disabled>
                            {this.props.intl.formatMessage({
                              id:
                                "myihtp.infopessoal.parametrizacoes.moedaMessage"
                            })}
                          </option>
                          {currencies.list
                            ? currencies.list.map((currency, j) => {
                              return (
                                <option value={currency.ID_Moeda}>
                                  {currency.Descricao}
                                </option>
                              );
                              // );
                            })
                            : null}
                        </NativeSelect>
                        {/* {this.state.currencies.length > 0 ? (
                          
                        ) : null} */}
                      </FormControl>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "center" }}
                    >
                      <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.infopessoal.botao.guardar" })}
                        toUpper={true}
                        style={{ minWidth: "20%" }}
                        onClick={this.guardarParam} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    languages: state.languages,
    currencies: state.currencies
  };
};

export default injectIntl(connect(mapStateToProps)(OutrasInformacoes));
