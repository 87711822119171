import { TextField } from "@material-ui/core";
import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { ToastContainer } from "react-toastr";
import { editPassword } from "../../../../../actions/myihtpActions";
import IHTPButton from "../../../../Common/IHTPButton";

let container;
class GerirConta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordChange: {
        newPassword: "",
        confirmNewPassword: ""
      }
    };
    this.handleInput = this.handleInput.bind(this);
  }

  editPassword = () => async e => {
    e.stopPropagation();
    editPassword(this.state.passwordChange)
      .then(data => {
        if (data.success === true) {
          container.clear();
          container.success(data.message);
        } else {
          container.clear();
          container.error(data.message);
        }
      })
      .catch(error => {
        container.clear();
        container.error("Ocorreu um erro ao alterar a sua palavra-passe");
      });
  };

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        passwordChange: {
          ...prevState.passwordChange,
          [name]: value
        }
      })
    );
  }
  render() {
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <Row style={{ textAlign: "left" }}>
              <Col xs="12" sm="12" md="12" lg="12">
                <div className="form-group">
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.alterarPassword.novaPassword" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "myihtp.infopessoal.alterarPassword.novaPassword"
                        })}
                        name={"newPassword"}
                        type="password"
                        fullWidth={true}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.alterarPassword.confirmNovaPassword" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id:
                            "myihtp.infopessoal.alterarPassword.confirmNovaPassword"
                        })}
                        name={"confirmNewPassword"}
                        type="password"
                        fullWidth={true}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.infopessoal.botao.guardar" })}
                        toUpper={true}
                        style={{ minWidth: "20%" }}
                        onClick={this.editPassword()} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
export default injectIntl(GerirConta);
