import IhtpSite from "./IhtpSite";
import SiteClass from "./Site";
import WonderlivesSite from "./WonderlivesSite";


export class SiteFactoryClass {
    private static instance: SiteFactoryClass;
    public site: SiteClass | undefined;

    private constructor() {
    }

    public static GetInstance(): SiteFactoryClass {
        if (!SiteFactoryClass.instance) {
            SiteFactoryClass.instance = new SiteFactoryClass();
        }

        return SiteFactoryClass.instance;
    }

    public SetSite(site: string) {
        if (site === "wonderlives") {
            this.site = WonderlivesSite;
        }

        if (site === "ihtp") {
            this.site = IhtpSite;
        }
    }
}