import { FormControl, InputLabel, OutlinedInput, Select } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import postalCodes from 'postal-codes-js';
import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { getPaises } from "../../../../../../actions/miniCartActions";
import { addMoradas, getTipoMoradas } from "../../../../../../actions/myihtpActions";
import IHTPButton from "../../../../../Common/IHTPButton";

let container;

class DialogNovaMorada extends Component {
  constructor(props) {
    super(props);
    //alert(this.props.currency);
    this.state = {
      addMorada: {
        Localidade: "",
        CodigoPostal: "",
        ID_Pais: "",
        Nome: "",
        Nif: "",
        Principal: "",
        Morada: "",
        ID_Tipo_Morada: ""
      },
      tipoMoradas: [],
      paises: []
    };
    this.handleAddInput = this.handleAddInput.bind(this);

  }
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  componentDidMount() {
    getTipoMoradas().then(moradas => {
      if (!moradas.moradas.error) {
        this.setState({ tipoMoradas: moradas.moradas });
        this.props.dispatch(getPaises()).then(data => {
          if (!data.data.error) {
            const paises = data.data.paises.map(item => {
              item.id = item.alpha3;
              item.title = item.name;
              return item;
            });

            this.setState({ paises: paises });
          }
        });
      }
    });
  }

  addMorada = () => async e => {
    e.stopPropagation();

    //Se código postal não estiver definido não deve poder continaur
    if (!this.state.addMorada.CodigoPostal) {
      container.clear();
      container.error(this.props.intl.formatMessage({ id: "myihtp.infopessoal.moradas.codigoPostalValido" }));
      return;
    }

    //Se Pais estiver definido não deve poder continaur
    if (!this.state.addMorada.ID_Pais) {
      container.clear();
      container.error(this.props.intl.formatMessage({ id: "myihtp.infopessoal.moradas.paisValido" }));
      return;
    }

    var resultValidate = postalCodes.validate(this.state.addMorada.ID_Pais, this.state.addMorada.CodigoPostal);
    if (resultValidate !== true) {
      container.clear();
      container.error(this.props.intl.formatMessage({ id: "myihtp.infopessoal.moradas.codigoPostalValido" }));
      return;
    }

    let regex = new RegExp(/^\d{4}([-]\d{3})$/g);
    if (this.state.addMorada.ID_Pais === 'PRT' && regex.test(this.state.addMorada.CodigoPostal) === false) {
      container.clear();
      container.error(this.props.intl.formatMessage({ id: "myihtp.infopessoal.moradas.codigoPostalValidoPortugal" }));
      return;
    }

    addMoradas(this.state.addMorada)
      .then(data => {
        if (data.success === true) {
          this.props.onClose(this.props.selectedValue);
          this.props.updateMoradasFunction();
          container.clear();
          container.success(data.message);

          this.state.addMorada = {
            ID_Tipo_Morada: "",
            Localidade: "",
            CodigoPostal: "",
            ID_Pais: "",
            Nome: "",
            Nif: "",
            Principal: ""
          };

        } else {
          container.clear();
          container.error(`${data.message}`);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleAddInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        addMorada: {
          ...prevState.addMorada,
          [name]: value
        }
      }),
      () => console.log(this.state.addMorada)
    );
  }

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          style={{ minHeight: "100%" }}
          {...other}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <Container>
            {/* Parte de cima com titulo e botão de fechar */}
            <Row>

              {/* Titulo */}
              <Col xs="10" sm="10" md="10" lg="10">
                <DialogTitle id="simple-dialog-title">
                  <FormattedMessage id="myihtp.infopessoal.moradas.adicionarTitulo" />
                </DialogTitle>
              </Col>

              {/* Botão de fechar dialogo */}
              <Col xs="2" sm="2" md="2" lg="2"
                onClick={this.handleClose}
                style={{
                  display: "flex",
                  alignItems: "center",
                  right: "10px"
                }}
              >
                <svg
                  width="33px"
                  height="33px"
                  viewBox="0 0 33 33"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Menu"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="MD"
                      transform="translate(-1311.000000, -21.000000)"
                      fill="#000000"
                    >
                      <g
                        id="close-(1)"
                        transform="translate(1311.000000, 21.000000)"
                      >
                        <path
                          d="M32.6096072,0.390441109 C32.0890191,-0.130147036 31.245005,-0.130147036 30.7244169,0.390441109 L0.390441109,30.7243524 C-0.130147036,31.2449405 -0.130147036,32.0889546 0.390441109,32.6095428 C0.650702954,32.8699335 0.991917965,33 1.33306852,33 C1.67421908,33 2.01536964,32.8698691 2.27569594,32.6094783 L32.6096072,2.27556703 C33.1301309,1.75504334 33.1301309,0.911029253 32.6096072,0.390441109 Z"
                          id="Path"
                        />
                        <path
                          d="M32.6095985,30.7243524 L2.27557092,0.390441109 C1.75504634,-0.130147036 0.910966357,-0.130147036 0.390441776,0.390441109 C-0.130147259,0.9109648 -0.130147259,1.75497888 0.390441776,2.27556703 L30.7244694,32.6095428 C30.9847317,32.8698691 31.3259472,33 31.6670984,33 C32.0082495,33 32.3494651,32.8698691 32.609663,32.6096072 C33.1301231,32.0889546 33.1301231,31.2449405 32.6095985,30.7243524 Z"
                          id="Path"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </Col>
            </Row>

            {/* Tipo de Morada */}
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                {this.state.tipoMoradas.length > 0 ? (
                  <FormControl variant="outlined" fullWidth={true} maxWidth={"sm"} >
                    <InputLabel
                      ref={ref => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="outlined-age-simple"
                    >
                      <FormattedMessage id="myihtp.infopessoal.moradas.ID_TipoMorada" />
                    </InputLabel>
                    <Select
                      native
                      value={this.state.ID_Tipo_Morada}
                      onChange={this.handleAddInput}
                      input={
                        <OutlinedInput
                          labelWidth={127}
                          name="ID_Tipo_Morada"
                          id="outlined-age-simple"
                        />
                      }
                    >
                      <option value="" />
                      {this.state.tipoMoradas.map((morada, j) => {
                        return (
                          <option value={morada.ID_TipoMorada}>
                            {morada.Descricao}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : null}
              </Col>
            </Row>

            {/* Nome */}
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }} >
                <TextField
                  id="Nome"
                  type="text"
                  label={this.props.intl.formatMessage({
                    id: "myihtp.infopessoal.moradas.nome"
                  })}
                  name="Nome"
                  onChange={this.handleAddInput}
                  margin="normal"
                  variant="outlined"
                  style={{ fontSize: "12px" }}
                  fullWidth={true}
                  maxWidth={"sm"}
                />
              </Col>
            </Row>

            {/* Morada */}
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                <TextField id="Morada" type="text"
                  label={this.props.intl.formatMessage({ id: "myihtp.infopessoal.moradas.Morada" })}
                  name="Morada"
                  onChange={this.handleAddInput}
                  margin="normal"
                  variant="outlined"
                  style={{ fontSize: "12px" }}
                  fullWidth={true}
                  maxWidth={"sm"}
                />
              </Col>
            </Row>

            {/* Localidade */}
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }} >
                <TextField
                  id="Localidade"
                  type="text"
                  label={this.props.intl.formatMessage({
                    id: "myihtp.infopessoal.moradas.Localidade"
                  })}
                  name="Localidade"
                  onChange={this.handleAddInput}
                  margin="normal"
                  variant="outlined"
                  style={{ fontSize: "12px" }}
                  fullWidth={true}
                  maxWidth={"sm"}
                />
              </Col>
            </Row>

            {/* Código Postal */}
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }} >
                <TextField
                  id="CodigoPostal"
                  type="text"
                  label={this.props.intl.formatMessage({
                    id: "myihtp.infopessoal.moradas.CodigoPostal"
                  })}
                  name="CodigoPostal"
                  onChange={this.handleAddInput}
                  margin="normal"
                  variant="outlined"
                  style={{ fontSize: "12px" }}
                  fullWidth={true}
                  maxWidth={"sm"}
                />
              </Col>
            </Row>

            {/* Paises */}
            <Row style={{ marginTop: "16px" }}>
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                {this.state.paises.length > 0 ? (
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    maxWidth={"sm"}
                  >
                    <InputLabel
                      ref={ref => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="outlined-age-simple"
                    >
                      <FormattedMessage id="myihtp.infopessoal.moradas.Pais" />
                    </InputLabel>
                    <Select
                      native
                      value={this.state.ID_Pais}
                      onChange={this.handleAddInput}
                      input={
                        <OutlinedInput
                          labelWidth={127}
                          name="ID_Pais"
                          id="outlined-age-simple"
                        />
                      }
                    >
                      <option value="" />
                      {this.state.paises.map((paises, j) => {
                        return (
                          <option value={paises.id}> {paises.title}</option>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : null}
              </Col>
            </Row>

            {/* Nif */}
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                <TextField
                  id="Nif"
                  type="text"
                  label={this.props.intl.formatMessage({
                    id: "myihtp.infopessoal.moradas.nif"
                  })}
                  name="Nif"
                  onChange={this.handleAddInput}
                  margin="normal"
                  variant="outlined"
                  style={{ fontSize: "12px", marginTop: "5px" }}
                  fullWidth={true}
                  maxWidth={"sm"}
                />
              </Col>
            </Row>

            {/* Morada Principal */}
            <Row style={{ marginTop: "16px" }}>
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                <FormControl
                  variant="outlined"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-age-simple"
                  >
                    <FormattedMessage id="myihtp.infopessoal.moradas.moradaPrincipal" />
                  </InputLabel>
                  <Select
                    native
                    value={this.state.Principal}
                    onChange={this.handleAddInput}
                    input={
                      <OutlinedInput
                        labelWidth={127}
                        name="Principal"
                        id="outlined-age-simple"
                      />
                    }
                  >
                    <option value="" />
                    <option value={"S"}>
                      {this.props.intl.formatMessage({
                        id: "myihtp.infopessoal.moradas.sim"
                      })}
                    </option>
                    <option value={"N"}>
                      {this.props.intl.formatMessage({
                        id: "myihtp.infopessoal.moradas.nao"
                      })}
                    </option>
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <br />

            {/* Botão de adicionar morada*/}
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.infopessoal.moradas.adicionarBotao" })}
                  toUpper={true}
                  onClick={this.addMorada()} />
              </Col>
            </Row>
            <br />
          </Container>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default injectIntl(connect(mapStateToProps)(DialogNovaMorada));
