import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SiteFactoryClass } from '../../../../classes/SiteFactory';
import FacebookLoginComponent from './FacebookLoginComponent';
import GoogleLoginComponent from './GoogleLoginComponent';

export interface SocialLoginProps {
    previousInCheckout: boolean
}

const siteInstance = SiteFactoryClass.GetInstance();

export default function SocialLogin(props: SocialLoginProps) {

    const [googleErrorMessage, setGoogleErrorMessage] = useState<string>("");

    return (
        <>
            {(siteInstance.site?.hasFacebookLogin || siteInstance.site?.hasGoogleLogin) &&
                <>
                    <Row style={{ display: "flex", alignItems: "center", alignContent: "center" }}>
                        {siteInstance.site.hasFacebookLogin &&
                            < Col md={6} className="colsFBButton">
                                <FacebookLoginComponent previousInCheckout={props.previousInCheckout} />
                            </Col>
                        }

                        {siteInstance.site.hasGoogleLogin &&
                            <Col md={6} className="colsGButton">
                                <GoogleLoginComponent
                                    onErrorMessage={(message) => setGoogleErrorMessage(message)}
                                    previousInCheckout={props.previousInCheckout} />
                            </Col>
                        }
                    </Row >

                    {googleErrorMessage != null && googleErrorMessage.length > 0 ?
                        <Row style={{ marginTop: "25px" }}>
                            <Col>
                                <label style={{ fontSize: "15px" }}>{googleErrorMessage}</label>
                            </Col>
                        </Row>
                        : null
                    }
                </>
            }
        </>
    )
}
