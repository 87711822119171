import $ from "jquery";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MenuButton from "../../Header/Components/MenuButton";
// import MenuButton from "../../../Header/MenuButton";
import IhtpSite from "../../../../classes/IhtpSite";
import { SiteFactoryClass } from "../../../../classes/SiteFactory";
import "./Sidebar.css";
import SidebarItem from "./SidebarItem";
import SiteLinksHelper from "../../../../helpers/SiteLinksHelper";

const siteInstance = SiteFactoryClass.GetInstance();

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConsultor: true,
      sideBar: false
    };
    this.elementRef = React.createRef();
  }

  openSideBar = async e => {
    if (!this.state.sideBar) {
      $(".displaySidebar").css("display", "block");

      this.setState({
        sideBar: true
      });
    } else {
      $(".displaySidebar").css("display", "none");

      this.setState({
        sideBar: false
      });
    }
  };

  render() {
    var page = this.props.page;
    const accountType = this.props.userTypeDescription;

    return (
      <div>

        {/* Menu Para Mobile */}
        <Row>
          <Col xs="9" sm="9" className="infoCreditosMobile" style={{ textAlign: "center" }}>
            <FormattedMessage id="myihtp.cumprimentar" /> {this.props.user.userName} <br />
            <FormattedMessage id="myihtp.creditosDisponiveis" /> {this.props.user.credits}
            <br />
            <FormattedMessage id="myihtp.tipoConta" /> {accountType}
          </Col>

          <Col xs="3" sm="3" onClick={() => this.openSideBar()} className="buttonMobileMenu" style={{ textAlign: "right" }}>
            <MenuButton color={siteInstance.site?.labelColour} />
          </Col>
        </Row>

        <Container className="infoCreditos">
          <br />
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <FormattedMessage id="myihtp.cumprimentar" /> {this.props.user.userName}
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <FormattedMessage id="myihtp.creditosDisponiveis" /> {this.props.user.credits}
            </Col>
          </Row>

          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <FormattedMessage id="myihtp.tipoConta" />{" "}
              {accountType}
            </Col>
          </Row>

        </Container>
        <hr />
        <Container className="displaySidebar">
          <SidebarItem page={page} itemName="Dashboard" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.dashboard" })} />

          {siteInstance.site === IhtpSite &&
            <SidebarItem page={page} itemName="SuccessBox" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.successBox" })} />
          }

          <SidebarItem page={page} itemName="InformacoesPessoais" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.informacoesPessoais" })} />

          {this.props.user.userType === "CS" &&
            <SidebarItem page={page} itemName="FerramentasConsultor" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.ferramentasConsultor" })} />
          }
          {this.props.user.isAdmin === true &&
            <>
              <SidebarItem page={page} itemName="FerramentasBackOffice" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.ferramentasBackOffice" })} />
              <SidebarItem page={page} itemName="EncomendasBackOffice" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.encomendasBackOffice" })} />
            </>
          }

          <SidebarItem page={page} itemName="Premios" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.premios" })} />
          <SidebarItem page={page} itemName="Vales" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.vales" })} />

          {this.props.user.userType !== "CF" &&
            <SidebarItem page={page} itemName="Comissoes" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.comissoes" })} />
          }

          <SidebarItem page={page} itemName="Encomendas" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.encomendas" })} />
          <SidebarItem page={page} itemName="MeusProdutos" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.meusProdutos" })} />
          <SidebarItem page={page} itemName="MediaCenter" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.mediaCenter" })} />
          <SidebarItem page={page} itemName="NotificacoesEmail" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.notificacoesEmail" })} />
          <SidebarItem page={page} itemName="Downloads" label={this.props.intl.formatMessage({ id: "myihtp.sidebar.downloads" })} />

          {["CS", "CT"].includes(this.props.user.userType?.toUpperCase()) &&
            <SidebarItem page={page}
              itemName={SiteLinksHelper.links.resellUrl}
              label={this.props.intl.formatMessage({ id: "myihtp.sidebar.revenda" })} />
          }

          {this.props.user.acessoCrm === "S" &&
            <SidebarItem page={page}
              itemName={SiteLinksHelper.links.crmUrl}
              label={this.props.intl.formatMessage({ id: "myihtp.sidebar.crm" })} />
          }
          <hr style={{ display: isMobile ? "block" : "none" }} />
        </Container>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.user,
    userTypeDescription: state.userTypeDescription
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(Sidebar)));
