import { FormControl, InputLabel, OutlinedInput, Select } from "@material-ui/core";
import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { ToastContainer } from "react-toastr";
import { cancelNotification, getDesafios, getNotificacoesEmail } from "../../../../../actions/myihtpActions";
import IHTPButton from "../../../../Common/IHTPButton";
import { subscribeNotification } from "./Helper";

let container;
class NotificacoesEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificacoes: [],
      desafios: [],
      addInfo: {
        TipoDesafio: ""
      },
      loading: false,
      loadingItem: null,
      loadingRemove: false
    };
    this.cancelNotificacao = this.cancelNotificacao;
    this.handleAddInput = this.handleAddInput.bind(this);
  }

  componentDidMount() {
    getNotificacoesEmail()
      .then(data => {
        if (data.notificacoes.length > 0) {
          getDesafios()
            .then(data => {
              this.setState(prevState => ({
                ...prevState,
                desafios: data.desafios
              }));
            })
            .catch(err => {
              console.log(err);
            });
          this.setState(prevState => ({
            ...prevState,
            notificacoes: data.notificacoes
          }));
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleAddInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        addInfo: {
          ...prevState.addInfo,
          [name]: value
        }
      })
    );
  }

  cancelNotificacao = (id, type) => {

    this.setState(prevState => ({
      ...prevState,
      loadingRemove: true,
      loadingItem: type
    }))

    if (type === "DDS") {
      this.setState(prevState => ({
        ...prevState,
        addInfo: {
          ...prevState.addInfo,
          TipoDesafio: ""
        }
      }));
    }

    cancelNotification(id)
      .then(response => {
        if (response.success === true) {
          getNotificacoesEmail()
            .then(data => {
              this.setState(prevState => ({
                ...prevState,
                notificacoes: data.notificacoes
              }));
              container.clear();
              container.success(response.message);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          container.clear();
          container.error(response.message);
        }

        this.setState(prevState => ({
          ...prevState,
          loadingRemove: false,
          loadingItem: type
        }))
      })
      .catch(err => {

        this.setState(prevState => ({
          ...prevState,
          loadingRemove: false,
          loadingItem: type
        }))

        container.clear();
        container.error(this.props.intl.formatMessage({ id: "myihtp.notificacoes.erroCancelar" }));
      });
  };

  subscribeNotification = type => {

    //Se não for do tipo dia da semana
    if (type !== "DDS") {
      this.state.addInfo = '';
    }

    this.setState(prevState => ({
      ...prevState,
      loading: true,
      loadingItem: type
    }))

    subscribeNotification(type, this.state.addInfo)
      .then(response => {

        if (response.success === true) {
          getNotificacoesEmail()
            .then(data => {
              this.setState(prevState => ({
                ...prevState,
                notificacoes: data.notificacoes
              }));
              container.clear();
              container.success(response.message);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          container.clear();
          container.error(response.message);
        }

        this.setState(prevState => ({
          ...prevState,
          loading: false,
          loadingItem: null
        }))
      })
      .catch(err => {

        this.setState(prevState => ({
          ...prevState,
          loading: false,
          loadingItem: null
        }))

        container.clear();
        container.error(this.props.intl.formatMessage({ id: "myihtp.notificacoes.erroCriar" }));
      });
  };

  render() {
    var that = this;
    return (
      <Row>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Col xs="12" sm="12" md="12" lg="12">
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              {this.state.notificacoes.map(function (item) {
                return (
                  <div key={item.id_tipoalertasubscrito}>
                    <Row>
                      <Col xs="12" sm="12" md="7" lg="7">
                        <h5>{item.Descricao}</h5>
                      </Col>
                      {item.datadesactivacao ? null : (
                        <Col xs="12" sm="12" md="5" lg="5">
                          <FormattedMessage id="myihtp.notificacoes.subscrito" />{" "}
                          {item.datacriacao}
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" md="12" lg="12">
                        <p>{item.Texto}</p>
                      </Col>
                      <br />
                      <br />
                      <br />
                      {item.datadesactivacao ? (
                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          style={{
                            alignItems: "center",
                            textAlign: "right"
                          }}
                        >
                          {that.state.desafios.length > 0 ? (
                            item.id_tipoalertasubscrito === "DDS" ? (
                              <FormControl variant="outlined">
                                <InputLabel
                                  ref={ref => {
                                    that.InputLabelRef = ref;
                                  }}
                                  htmlFor="outlined-age-native-simple"
                                >
                                  <FormattedMessage id="myihtp.notificacoes.desafio" />{" "}
                                </InputLabel>
                                <Select
                                  native
                                  // value={this.state.desafio}
                                  onChange={that.handleAddInput}
                                  input={
                                    <OutlinedInput
                                      name="TipoDesafio"
                                      labelWidth={70}
                                      id="outlined-age-native-simple"
                                    />
                                  }
                                >
                                  <option value="" />
                                  {that.state.desafios.map((desafio, j) => {
                                    return (
                                      <option key={j} value={desafio.ID_Desafio}>
                                        {desafio.Descricao}
                                      </option>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            ) : null
                          ) : null}
                          <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.notificacoes.subscrib" })}
                            toUpper={true}
                            style={{ backgroundColor: "green", margin: "9px 0px 0px 10px" }}
                            loading={that.state.loading === true && that.state.loadingItem === item.id_tipoalertasubscrito}
                            onClick={() => that.subscribeNotification(item.id_tipoalertasubscrito)} />
                        </Col>
                      ) : (
                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "right" }}>
                          <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.notificacoes.removerSubscricao" })}
                            toUpper={true}
                            style={{ backgroundColor: "red" }}
                            loading={that.state.loadingRemove === true && that.state.loadingItem === item.id_tipoalertasubscrito}
                            onClick={this.getMediaCenterWithMediaType()} />
                        </Col>
                      )}
                    </Row>
                    <hr />
                  </div>
                );
              })}
              <br />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
export default injectIntl(NotificacoesEmail);
