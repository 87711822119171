export function getPaises() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/getCountries", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(res => res.json())
                .then(json => {
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

export function getPositions() {
    return new Promise((resolve, reject) => {
        return fetch("/geral/getPositions", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => console.log(error));
    });
}

//Método para ficar com id de app da google
export function getGoogleAppId() {
    return new Promise((resolve, reject) => {
        return fetch("/geral/getGoogleAppId", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => {
                resolve({
                    success: false,
                    message: "",
                    obj: null
                })
            });
    });
}

//Método para ficar com id de app da facebook
export function getFacebookAppId() {
    return new Promise((resolve, reject) => {
        return fetch("/geral/getFacebookAppId", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => {
                resolve({
                    success: false,
                    message: "",
                    obj: null
                })
            });
    });
}

//Método para ficar com key da recaptcha
export function getGoogleRecaptchaKey() {
    return new Promise((resolve, reject) => {
        return fetch("/geral/getGoogleRecaptchaKey", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => {
                resolve({
                    success: false,
                    message: "",
                    obj: null
                })
            });
    });
}

export function getGenders() {
    return new Promise((resolve, reject) => {
        return fetch("/geral/getGenders", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => console.log(error));
    });
}

export function getSite() {
    return new Promise((resolve) => {
        fetch("/site")
            .then(res => res.json())
            .then((result) => {
                resolve(result);
            })
    })
}