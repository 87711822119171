import { NativeSelect, TextField } from "@material-ui/core";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import ImageUploader from "react-images-upload";
import { useIntl } from "react-intl";
import { associateImageWithPack, associateImageWithProduct } from "../../../../../../../actions/myihtpActions";
import Toast from "../../../../../../../helpers/Toast";
import IHTPButton from "../../../../../../Common/IHTPButton";

export default function AddImage() {
    const [productId, setProductId] = useState<string>("");
    const [selectedType, setSelectedType] = useState<string>("");
    const [file, setFile] = useState<File | null>();
    const [isAssociatingImage, setIsAssociatingImage] = useState<boolean>(false);

    const disableAssociateButton = !productId || !selectedType || !file;

    const intl = useIntl();

    const handleClickImport = async () => {
        if (disableAssociateButton) {
            return;
        }

        setIsAssociatingImage(true);
        var resultAssociateImage: { success: boolean, message: string } = { success: false, message: "" };

        if (selectedType === "product") {
            resultAssociateImage = await associateImageWithProduct(file, productId);
        }
        if (selectedType === "pack") {
            resultAssociateImage = await associateImageWithPack(file, productId);
        }

        setIsAssociatingImage(false);

        if (resultAssociateImage.success === false) {
            Toast.Show("error", resultAssociateImage.message);
            return;
        }

        Toast.Show("success", "Imagem associada com sucesso ao produto");
        setFile(null);
        setSelectedType("");
        setProductId("");
    }

    return (
        <>
            <Row style={{ textAlign: "left" }}>
                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                    <span style={{ textAlign: "center" }}>
                        <b>Associar imagem a produto/pack</b>
                    </span>
                </Col>
            </Row>

            <div className="form-group">
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                        <TextField placeholder={intl.formatMessage({ id: "myihtp.ferramentasBackOffice.productId.placeholder" })} name={"productId"} style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                            value={productId} onChange={(e) => setProductId(e.target.value)}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                        <NativeSelect name={"selectedType"} style={{ maxWidth: isMobile ? "100%" : "50%" }} fullWidth={true}
                            onChange={(e) => setSelectedType(e.target.value)} value={selectedType}>
                            <option value="">{intl.formatMessage({ id: "myihtp.ferramentasBackOffice.selectType.option" })}</option>
                            <option value="product">{intl.formatMessage({ id: "myihtp.ferramentasBackOffice.selectType.option.product" })}</option>
                            <option value="pack">{intl.formatMessage({ id: "myihtp.ferramentasBackOffice.selectType.option.pack" })}</option>
                        </NativeSelect>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                        <ImageUploader
                            withIcon={false}
                            withPreview={false}
                            style={{ maxWidth: "100%" }}
                            name="addProductImage"
                            label={file?.name ?? ""}
                            buttonText={"Escolha um ficheiro"}
                            onChange={(file: File[]) => setFile(file[0])}
                            imgExtension={[".png", ".jpg"]}
                            maxFileSize={999999999}
                            singleImage={true}
                        />
                    </Col>
                </Row>


                <Row style={{ justifyContent: "center" }}>
                    <Col xs="6" sm="6" md="6" lg="4" xl="3">
                        <IHTPButton text="Associar"
                            toUpper={true}
                            onClick={handleClickImport}
                            loading={isAssociatingImage}
                            disabled={disableAssociateButton} />
                    </Col>
                </Row>
            </div>
        </>
    )
}