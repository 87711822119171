import { Component } from "react";
import SetDefaultCurrency from "./Components/SetDefaultCurrency";
import AddImage from "./Components/AddImage";

export default class Produtos extends Component {
    render() {
        return (
            <>
                <div style={{ marginTop: "2em" }}>
                    <SetDefaultCurrency />
                </div>

                <div style={{ marginTop: "2em" }}>
                    <AddImage />
                </div></>
        )
    }
}