import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { instanceOf } from "prop-types";
import React from "react";
import { Cookies, CookiesProvider, withCookies } from "react-cookie";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App/App";

import messages_en from "./translations/en.json";
import messages_es from "./translations/es.json";
import messages_pt from "./translations/pt.json";
//import messages_fr from "./translations/fr.json";

import { changeLanguageFE, setLoadingAllPageContent } from "./actions/miniCartActions";
import SiteLinksHelper from "./helpers/SiteLinksHelper";


var uniqid = require("uniqid");

const messages = {
    pt: messages_pt,
    en: messages_en,
    es: messages_es,
    //fr: messages_fr
};

const Context = React.createContext();

const defaultLanguage = "pt";
class IntlProviderWrapper extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        var initialLanguage = this.GetLanguageToDisplay();

        this.switchTo = (language) => {
            var tmpLanguage = null;

            //Se não encontrarmos a linguagem mandamos a default
            if (typeof language === 'undefined' || language == null) {
                tmpLanguage = defaultLanguage;

            } else {
                tmpLanguage = language.toLowerCase();
                if (typeof messages[tmpLanguage] === 'undefined' || messages[tmpLanguage] == null) {
                    tmpLanguage = defaultLanguage;
                }
            }

            this.setState({ locale: tmpLanguage, messages: messages[tmpLanguage] }, () => {
                setTimeout(() => {
                    this.props.dispatch(setLoadingAllPageContent(false));
                }, 500);

                //Este reload é necessário para que o TawkTo se ajuste ao novo idioma
                location.reload();
            });
        }

        var tmpMessages = [];
        if (typeof messages[initialLanguage] === 'undefined') {
            tmpMessages = messages[defaultLanguage];
            initialLanguage = defaultLanguage;
        } else {
            tmpMessages = messages[initialLanguage];
        }

        this.state = {
            locale: initialLanguage,
            messages: tmpMessages,
            switchTo: this.switchTo,
        };


    }

    //Depois de todo o componente carregar enviamos informação do locale selecionado
    componentDidMount() {
        this.props.dispatch(changeLanguageFE(this.state.locale.toUpperCase()));
        SiteLinksHelper.SetSitesUrlAccordingToLanguage(this.state.locale.toUpperCase())
    }


    //Calcular qual o idioma para mostrar o site
    GetLanguageToDisplay() {
        const { cookies } = this.props;

        //Vamos buscar o id do utilizador
        const userId = this.props.user.userID;
        const availableLanguages = this.props.languages;

        let language = "";

        //Se o id não estiver preenchido e a cookie not set
        if ((typeof userId === 'undefined' || userId == null) && !cookies.get("validateDefaultLanguage")) {
            cookies.set("validateDefaultLanguage", uniqid() + uniqid(), {
                path: "/",
                maxAge: 604800 //Definimos uma semana
            });

            //Vamos buscar o idioma do site
            var browserLanguage = navigator.language;

            //Ficamos com a primeira parte do idioma, por exemplo: pt em pt-PT
            language = browserLanguage.split("-")[0];

        } else { //Se o utilizador estiver logado, mandamos o idioma
            language = this.props.user.language;
        }

        const languageFound = availableLanguages.find(a => a.ID_Lingua.toLowerCase() === language.toLowerCase());
        if (!languageFound) {
            language = availableLanguages[0].ID_Lingua;
        }

        return language;
    }

    render() {
        var user = this.props.user;
        const { locale } = this.state;
        return (
            <Context.Provider value={this.state}>
                <IntlProvider key={locale} locale={locale} timeZone="UTC" messages={messages[locale]}>
                    <BrowserRouter>
                        <MuiThemeProvider>
                            <CookiesProvider>
                                <App userID={user.userID} locale={locale} />
                            </CookiesProvider>
                        </MuiThemeProvider>
                    </BrowserRouter>
                </IntlProvider>
            </Context.Provider>
        )
    }
}
const mapStateToProps = state => {
    return {
    };
};

export default withCookies(connect(mapStateToProps)(IntlProviderWrapper));
export const IntlContext = Context;