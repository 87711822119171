import { TextField } from "@material-ui/core";
import { Component } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { deleteCompany, getCompaniesByConsultantId } from "../../../../../../../actions/myihtpActions";
import Configs from "../../../../../../../helpers/Configs";
import IHTPButton from "../../../../../../Common/IHTPButton";

const columns = [
    {
        Header: <FormattedMessage id="ferramentasUtilizador.minhasEmpresas.nomeEmpresa" />,
        accessor: "Nome",
        resizable: false,
        width: 200
    }, {
        Header: <FormattedMessage id="ferramentasUtilizador.minhasEmpresas.nif" />,
        accessor: "NIF",
        resizable: false,
    },
    {
        Header: <FormattedMessage id="ferramentasUtilizador.minhasEmpresas.localidade" />,
        accessor: "Localidade",
        resizable: false,
    },
    {
        Header: <FormattedMessage id="ferramentasUtilizador.minhasEmpresas.nomeConsultor" />,
        accessor: "NomeConsultor",
        resizable: false,
    },
    {
        Header: "",
        accessor: "Remover",
        width: 50,
        Cell: row => {
            return (
                <div>
                    <a>
                        <svg height="15px" viewBox="0 0 512 512.00115" width="15px" xmlns="http://www.w3.org/2000/svg">
                            <path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
                            <path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                            <path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                            <path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
                        </svg>
                    </a>
                </div>
            );
        }
    }
]

let container;
class MinhasEmpresas extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id_consultor: '',
            nif: '',
            companies: [],
            expanded: {},
            activeIndex: "",
            currentCompany: {
                name: '',
                nif: '',
                email: '',
                phonenumber: '',
                city: '',
                country: ''
            }
        }

        this.handleInput = this.handleInput.bind(this);
        this.loadCompanies = this.loadCompanies.bind(this);
        this.deleteCompanyClick = this.deleteCompanyClick.bind(this);
    }

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }
    componentDidMount() {
        this.loadCompanies();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.newCompany !== this.props.newCompany) {
            this.handleRowExpanded('', this.state.activeIndex);
            this.loadCompanies();
        }
    }

    loadCompanies() {
        getCompaniesByConsultantId(this.state.nif)
            .then(result => {
                if (result.success === true) {
                    this.handleRowExpanded('', this.state.activeIndex);
                    this.setState(
                        prevState => ({
                            ...prevState,
                            companies: result.obj
                        })
                    );
                }
            })
            .catch(err => {

            })
    }

    handleRowExpanded(rowsState, index) {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]]
            },
            activeIndex: [index[0]]
        });
    }


    deleteCompanyClick(idEmpresa) {
        deleteCompany(idEmpresa)
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);
                    this.loadCompanies();
                } else {
                    container.error(result.message);
                }
            })
            .catch(err => {
                console.log(err);
                container.clear();
                container.error(<FormattedMessage id="ferramentasUtilizador.minhasEmpresas.erroEliminar" />);
            })
    }

    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW alterar empresa a que consultor está atribuido*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b><FormattedMessage id="ferramentasUtilizador.minhasEmpresas.titulo" /></b>
                        </span>
                        <div className="form-group">
                            <br />

                            <Row xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", textAlign: "center" }}>
                                <Col xs={12} sm={12} md={4} lg={3} style={{ marginBottom: "5px" }}>
                                    <TextField
                                        placeholder={this.props.intl.formatMessage({
                                            id:
                                                "ferramentasUtilizador.minhasEmpresas.nifPlaceholder"
                                        })}
                                        name={"nif"} id={"nif"}
                                        type="text"
                                        fullWidth={true}
                                        onChange={this.handleInput} value={this.state.nif}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={3} style={{ marginBottom: "5px" }}>
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "ferramentasUtilizador.minhasEmpresas.procurar" })}
                                        toUpper={true}
                                        style={{ minWidth: "20%" }}
                                        onClick={this.loadCompanies} />
                                </Col>
                            </Row>

                            <br />

                            <ReactTable
                                className="-highlight tableReact"
                                data={this.state.companies}
                                expanded={this.state.expanded}
                                onExpandedChange={(newExpanded, index, event) =>
                                    this.handleRowExpanded(newExpanded, index, event)
                                }
                                getTheadProps={(state, rowInfo, column) => {
                                    return {
                                        style: {
                                            fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                                            fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                                            height: Configs.myIHTP.tableConfig.heightHeader,
                                            color: Configs.myIHTP.tableConfig.colorHeader,
                                            backgroundColor:
                                                Configs.myIHTP.tableConfig.backgroundColorHeader
                                        }
                                    };
                                }}
                                getPaginationProps={state => {
                                    return {
                                        style: {
                                            color: Configs.myIHTP.tableConfig.colorHeader,
                                            backgroundColor:
                                                Configs.myIHTP.tableConfig.backgroundColorHeader,
                                            fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                                        }
                                    };
                                }}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            if (column.id === "Remover") {

                                                var confirm = window.confirm(this.props.intl.formatMessage({ id: "ferramentasUtilizador.minhasEmpresas.removerEmpresa" }));

                                                //Se a resposta for afirmativa para remover a campanha
                                                if (confirm) {
                                                    this.deleteCompanyClick(rowInfo.original.Id);
                                                }
                                            }
                                            if (handleOriginal) {
                                                handleOriginal();
                                                this.setState(
                                                    prevState => ({
                                                        ...prevState,
                                                        currentCompany: {
                                                            ...prevState.currentCompany,
                                                            name: rowInfo.original.Nome,
                                                            nif: rowInfo.original.NIF,
                                                            email: rowInfo.original.Email,
                                                            phonenumber: rowInfo.original.Telemovel,
                                                            city: rowInfo.original.Localidade,
                                                            country: rowInfo.original.Pais
                                                        }
                                                    })
                                                );

                                            }

                                        },
                                        style: {
                                            "text-align": "center",
                                            "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                                        }
                                    };
                                }}
                                defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
                                showPagination={
                                    this.state.companies.length >
                                        Configs.myIHTP.tableConfig.defaultPageSize
                                        ? Configs.myIHTP.tableConfig.showPagination
                                        : false
                                }
                                previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                                nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                                noDataText={Configs.myIHTP.tableConfig.noDataText}
                                pageText={Configs.myIHTP.tableConfig.pageText}
                                ofText={Configs.myIHTP.tableConfig.ofText}
                                rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
                                columns={columns}
                                SubComponent={row => {
                                    return (
                                        <Container style={{ marginTop: "15px", marginBottom: "15px", marginLeft: "15px", marginRight: "15px", alignItems: "center" }}>
                                            <Row>

                                                {/* Nome da Campanha e Descrição da Campanha*/}
                                                <Col xs="12" sm="12" md="4" lg="4">
                                                    <Row>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField id="id_campaign" type="text" value={this.state.currentCompany.name}
                                                                label={<FormattedMessage id="ferramentasUtilizador.minhasEmpresas.nomeEmpresa" />} name="id_campaign" margin="normal"
                                                                variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField type="text" label={<FormattedMessage id="ferramentasUtilizador.minhasEmpresas.nif" />} name="NIF"
                                                                value={this.state.currentCompany.nif} readonly={true} disabled
                                                                margin="normal" variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs="12" sm="12" md="4" lg="4">
                                                    <Row>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={<FormattedMessage id="ferramentasUtilizador.minhasEmpresas.email" />}
                                                                margin="normal"
                                                                variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={this.state.currentCompany.email}
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={<FormattedMessage id="ferramentasUtilizador.minhasEmpresas.telemovel" />}
                                                                margin="normal" variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={this.state.currentCompany.phonenumber}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs="12" sm="12" md="4" lg="4">
                                                    <Row>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={<FormattedMessage id="ferramentasUtilizador.minhasEmpresas.localidade" />}
                                                                margin="normal" variant="outlined"
                                                                style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={this.state.currentCompany.city}
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={<FormattedMessage id="ferramentasUtilizador.minhasEmpresas.pais" />}
                                                                margin="normal" variant="outlined"
                                                                style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={this.state.currentCompany.country}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <br />
                                        </Container>
                                    );
                                }}></ReactTable>

                            <br />
                            <br />

                            <Row>

                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        newCompany: state.company
    };
};

export default injectIntl(connect(mapStateToProps)(MinhasEmpresas));