import { CircularProgress } from '@material-ui/core';
import { Component } from 'react';
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { FormattedMessage } from 'react-intl';
import { ToastContainer } from "react-toastr";
import { Col, Row } from 'reactstrap';
import { getSocialLoginAssociations, removeSocialLoginFromFacebook, removeSocialLoginFromGoogle } from '../../../../../../actions/userActions';
import IHTPButton from '../../../../../Common/IHTPButton';

let container;

export default class LoginSocial extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoginAssociatedWithFacebook: false,
            isRemovingFacebookAssociation: false,
            isLoginAssociatedWithGoogle: false,
            isRemovingGoogleAssociation: false
        }

        this.getSocialLogins = this.getSocialLogins.bind(this);
        this.removeFacebookAssociation = this.removeFacebookAssociation.bind(this);
        this.removeFacebookAssocationLoading = this.removeFacebookAssocationLoading.bind(this);
        this.removeGoogleAssociation = this.removeGoogleAssociation.bind(this);
        this.removeGoogleAssocationLoading = this.removeGoogleAssocationLoading.bind(this);
    }

    //Quando carregar o componente visual
    componentDidMount() {
        this.getSocialLogins();
    }

    //Método para buscarmos se o utilizador está registado com login social
    getSocialLogins() {

        var that = this;
        var promise = new Promise(function (resolve, reject) {
            getSocialLoginAssociations()
                .then(result => {
                    if (result.success === true) {
                        that.setState(prevState => ({
                            ...prevState,
                            isLoginAssociatedWithFacebook: (result.obj.facebook === true),
                            isLoginAssociatedWithGoogle: (result.obj.google === true),
                        }))

                        resolve();
                    } else {
                        container.clear();
                        container.error(result.message);

                        resolve();
                    }
                })
                .catch(err => {
                    container.clear();
                    container.error("Erro ao verificar os Logins Sociais do Utilizador");
                    resolve();
                })
        });
        return promise;
    }

    //Método para remover a associação de login atraves do Facebook
    removeFacebookAssociation() {

        this.removeFacebookAssocationLoading(true);

        removeSocialLoginFromFacebook()
            .then(result => {

                //Se a associação foi removida com sucesso de facebook
                if (result.success === true) {


                    container.clear();
                    container.success("Associação com Facebook removida");

                    this.getSocialLogins().then(result => {
                        this.removeFacebookAssocationLoading(false);
                    }).catch(err => {
                        this.removeFacebookAssocationLoading(false);
                    });

                    //Se tivemos um erro ao remover a associação do facebook
                } else {
                    this.removeFacebookAssocationLoading(false);
                    container.clear();
                    container.error(result.message);
                }
            })
            .catch(err => {
                this.removeFacebookAssocationLoading(false);

                container.clear();
                container.error("Erro ao remover a associação ao Facebook");
            })
    }

    //Método para colocar loading no método de remover associação por facebook
    removeFacebookAssocationLoading(value) {
        this.setState(prevState => ({
            ...prevState,
            isRemovingFacebookAssociation: value
        }));
    }

    //Método para colocar loading no método de remover associação por google
    removeGoogleAssocationLoading(value) {
        this.setState(prevState => ({
            ...prevState,
            isRemovingGoogleAssociation: value
        }));
    }

    //Método para remover a associação de login atraves do Googlee
    removeGoogleAssociation() {
        this.removeGoogleAssocationLoading(true);

        removeSocialLoginFromGoogle()
            .then(result => {

                //Se a associação foi removida com sucesso de Google
                if (result.success === true) {

                    container.clear();
                    container.success("Associação com Google removida");

                    this.getSocialLogins().then(result => {
                        this.removeGoogleAssocationLoading(false);
                    }).catch(err => {
                        this.removeGoogleAssocationLoading(false);
                    });

                    //Se tivemos um erro ao remover a associação do Google
                } else {
                    this.removeGoogleAssocationLoading(false);
                    container.clear();
                    container.error(result.message);
                }
            })
            .catch(err => {
                this.removeGoogleAssocationLoading(false);

                container.clear();
                container.error("Erro ao remover a associação ao Google");
            })

    }

    render() {
        return (
            <div>
                <ToastContainer
                    ref={ref => (container = ref)}
                    className="toast-bottom-right"
                />

                <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col xs={6} sm={6} md={6} lg={6}>
                        <label style={{ display: "flex", alignItems: "center", margin: "0px", justifyContent: "flex-end" }}>
                            <FormattedMessage id="myihtp.infopessoal.loginSocial.facebook" />
                            <FaFacebookF style={{ marginLeft: "10px", fontSize: "25px", color: "#3B5998" }} />:
                        </label>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        {this.state.isLoginAssociatedWithFacebook === true ? (
                            <div>


                                {this.state.isRemovingFacebookAssociation ? (
                                    <CircularProgress thickness={2} size={30} />
                                ) : (
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.infopessoal.loginSocial.removerAssociacao" })}
                                        toUpper={true}
                                        style={{ minWidth: "20%" }}
                                        onClick={this.removeFacebookAssociation} />
                                )}

                            </div>
                        ) : (
                            <label style={{ display: "flex", alignItems: "center", margin: "0px", justifyContent: "flex-start" }}>
                                <FormattedMessage id="myihtp.infopessoal.loginSocial.naoAssociado" />
                            </label>
                        )}
                    </Col>
                </Row>
                <br />
                <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col xs={6} sm={6} md={6} lg={6}>
                        <label style={{ display: "flex", alignItems: "center", margin: "0px", justifyContent: "flex-end" }}>
                            <FormattedMessage id="myihtp.infopessoal.loginSocial.google" />
                            <FcGoogle style={{ marginLeft: "10px", fontSize: "25px" }} />:
                        </label>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        {this.state.isLoginAssociatedWithGoogle === true ? (
                            <div>

                                {this.state.isRemovingGoogleAssociation ? (
                                    <CircularProgress thickness={2} size={30} color="black" />
                                ) : (
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.infopessoal.loginSocial.removerAssociacao" })}
                                        toUpper={true}
                                        style={{ minWidth: "20%" }}
                                        onClick={this.removeGoogleAssociation} />
                                )}

                            </div>
                        ) : (
                            <label style={{ display: "flex", alignItems: "center", margin: "0px", justifyContent: "flex-start" }}>
                                <FormattedMessage id="myihtp.infopessoal.loginSocial.naoAssociado" />
                            </label>
                        )}
                    </Col>
                </Row>
            </div >
        )
    }
}
