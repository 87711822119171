import { FormControl } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FlatButton, MenuItem, SelectField } from "material-ui";
import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { changePayment, getPaymentTypes } from "../../actions/miniCartActions";
import { addPhoneNumber, getPhoneNumbers } from "../../actions/userActions";
import { SiteFactoryClass } from "../../classes/SiteFactory";
import IHTPButton from "../Common/IHTPButton";
import Button2 from "../Forms/Button";

const styleLabel = {
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Roboto",
  color: "rgba(0, 0, 0, 0.3)"
}

let container;

const initialState = {
  paymentType: {
    type: null
  },
  pagamentoEmParcelas: false,
  parcelas: [],
  parcelaEscolhida: 1,
  pagamentoPorTelemovel: false,
  numeroTelemovelSelecionado: null,
  telemoveis: [],
  criarTelemovel: false,
  novoTelemovelNumero: null,
  novoTelemovelIndicativo: null,
  loading: false
}

const siteInstance = SiteFactoryClass.GetInstance();

class DialogChangePaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleAddInput = this.handleAddInput.bind(this);
    this.loadPhoneNumbers = this.loadPhoneNumbers.bind(this);
    this.clickToAddPhoneNumberForm = this.clickToAddPhoneNumberForm.bind(this);
    this.clickToHidePhoneNumberForm = this.clickToHidePhoneNumberForm.bind(this);
    this.addNewPhoneNumber = this.addNewPhoneNumber.bind(this);
    this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this);
    this.handleChangeNewPhoneNumber = this.handleChangeNewPhoneNumber.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  handleClose = () => {
    //Limpar a informação 
    this.cleanInformation();
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  handleChangeInstallments = (evento, index, value) => {

    this.setState(prevState => ({
      ...prevState,
      parcelaEscolhida: value,
    }));
  };

  loadPhoneNumbers() {
    getPhoneNumbers()
      .then(result => {
        var telemoveis = [];
        if (result.success === true) {
          telemoveis = result.obj
        }

        this.setState(prevState => ({
          ...prevState,
          telemoveis
        }));
      })
      .catch(err => {
        this.setState(prevState => ({
          ...prevState,
          telemoveis: []
        }));
      })
  }

  handleChangePaymentType = (event, index, value) => {

    //Procuramos o pagamento atual selecionado
    var currentPayment = this.props.paymentTypes.find(p => p.ID_TipoPagamento === value);

    //Verificamos se é um pagamento em parcelas
    var pagamentoEmParcelas = (currentPayment.EmParcelas === true);

    //Verificamos se é um pagamento por telemovel
    var numeroTelemovel = null;
    var pagamentoPorTelemovel = (currentPayment.PagamentoPorTelemovel === true);

    //Adicionamos o número de parcelas
    var parcelas = [];
    for (let i = 1; i <= currentPayment.NMaxParcelas; i++) {
      parcelas.push({ value: i });
    }

    //Se tivermos pagamento por telemovel definido, vamos buscar os numeros
    if (pagamentoPorTelemovel) {
      this.loadPhoneNumbers();
    }

    this.setState(prevState => ({
      ...prevState,
      paymentType: {
        ...prevState.paymentType,
        type: value
      },
      pagamentoEmParcelas,
      parcelas,
      parcelaEscolhida: 1,
      pagamentoPorTelemovel,
      numeroTelemovelSelecionado: numeroTelemovel
    }));
  };

  handleAddInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        tipoPagamento: {
          ...prevState.tipoPagamento,
          [name]: value
        }
      }),
      () => console.log(this.state.tipoPagamento)
    );
  }

  alterarTipoPagamento = () => async e => {

    //Validação extra para o tipo de pagamento estar selecionado
    if (this.state.paymentType.type == null) {
      container.clear();
      container.error(<FormattedMessage id="pagamento.alterarTipoPagamento.erro.naoSelecionouTipo" />);
      return;
    }

    //Validação extra para obrigar a ter Nº de Parcelas
    if (this.state.parcelaEscolhida <= 0 && this.state.pagamentoEmParcelas === true) {
      container.clear();
      container.error(`Não selecionou o Nº de Parcelas`);
      return;
    }

    //Validação extra se o utilizador, não selecionou um número de telemóvel
    if (this.state.pagamentoPorTelemovel === true && this.state.numeroTelemovelSelecionado == null) {
      container.clear();
      container.error(`Não selecionou um Número de Telemóvel`);
      return;
    }

    this.setLoading(true);

    changePayment(this.state.paymentType.type, this.props.encomendaID, this.props.hashEncomenda, this.state.parcelaEscolhida, this.state.novoTelemovelIndicativo + " " + this.state.novoTelemovelNumero)
      .then(data => {
        if (data.success === true) {
          this.setState(
            prevState => ({
              ...prevState,
              pagamentoEmParcelas: false,
              pagamentoPorTelemovel: false,
              criarTelemovel: false
            })
          );
          this.props.updatePaymentFunction();
          this.handleClose();
          container.clear();
          container.success(`${data.message}`);
          this.setLoading(false);
        } else {
          container.clear();
          container.error(`${data.message}`);
          this.setLoading(false);
        }
      })
      .catch(error => {
        console.log(error.message);

        container.clear();
        container.error("Erro ao comunicar com servidor para alterar Tipo de Pagamento. Tente novamente dentro de segundos.");
        this.setLoading(false);
      });
    e.stopPropagation();
  };

  componentDidMount() {
    this.props.dispatch(getPaymentTypes());
  }

  //Limpar a informação dos estados
  cleanInformation() {
    this.setState(prevState => ({
      ...initialState
    }));
  }

  //Método para abrir o formulário de adicionar telemóvel
  clickToAddPhoneNumberForm() {
    this.setState(prevState => ({
      ...prevState,
      criarTelemovel: true,
      numeroTelemovelSelecionado: null
    }))

  }

  //Método para esconder o formulário de adicionar telemóvel
  clickToHidePhoneNumberForm() {
    this.setState(prevState => ({
      ...prevState,
      criarTelemovel: false
    }))
  }

  //Método para adicionar novo número de telemóvel
  addNewPhoneNumber() {

    if (this.state.novoTelemovelIndicativo === "351" && this.state.novoTelemovelNumero.length !== 9) {
      container.clear();
      container.error("Telemóvel inserido inválido");
      return;
    }

    if (this.state.novoTelemovelIndicativo == null || this.state.novoTelemovelNumero == null) {
      container.clear();
      container.error("Telemóvel inserido inválido");
      return;
    }

    var phoneNumber = this.state.novoTelemovelIndicativo + " " + this.state.novoTelemovelNumero;
    addPhoneNumber(phoneNumber)
      .then(result => {
        if (result.success === true) {

          this.loadPhoneNumbers();

          this.setState(prevState => ({
            ...prevState,
            criarTelemovel: false,
            numeroTelemovelSelecionado: result.obj
          }));

          container.clear();
          container.success("Número de Telemóvel adicionado com sucesso");
        } else {
          container.clear();
          container.error(result.message);
        }
      })
      .catch(err => {
        container.clear();
        container.error("Erro ao adicionar Número de Telemóvel");
      });
  }

  //Método para permitir escolher tlm perante os valores existentes
  handleChangePhoneNumber(value, index, value2) {

    try {
      var numero = null;
      var tmpNumero = this.state.telemoveis.find(t => t.Id == value2);
      if (tmpNumero != null) {
        numero = tmpNumero.Descricao;
      }

      //Se numero estiver a null, significa que não encontramos o id do numero selecionado, 
      //nos numeros que vieram do servidor, por isso os dados não terão sido carregados corretamente
      //algo que nunca deve ocorrer, mas só para precaver
      if (numero == null) {
        container.clear();
        container.error("Erro ao carregar dados do Servidor");
        return;
      }

      var numeroComIndicativo = numero.trimStart().trimEnd().split(" ");

      //Verificamos se é um número válido
      if (numeroComIndicativo.length !== 2) {
        container.clear();
        container.error(<div><span> Número de Telemóvel inválido. O indicativo de país deve estar separado do número.</span><p style={{ color: "white" }}> Formáto válido: 351 912345678</p></div>);

        this.setState(prevState => ({
          ...prevState,
          numeroTelemovelSelecionado: null,
          novoTelemovelNumero: null,
          novoTelemovelIndicativo: null
        }));

      } else {
        this.setState(prevState => ({
          ...prevState,
          numeroTelemovelSelecionado: value2,
          novoTelemovelNumero: numeroComIndicativo[1],
          novoTelemovelIndicativo: numeroComIndicativo[0]
        }));
      }

    } catch (expection) {
      container.clear();
      container.error("Erro ao escolher Número de Telemóvel");
    }
  }

  //Guardar o valor do novo telemovel
  handleChangeNewPhoneNumber(value, data, event, formattedValue) {

    var dialCode = data.dialCode;
    var rawPhone = value.slice(data.dialCode.length)
    this.setState(prevState => ({
      ...prevState,
      novoTelemovelIndicativo: dialCode,
      novoTelemovelNumero: rawPhone
    }));
  }

  setLoading(value) {
    this.setState(prevState => ({
      ...prevState,
      loading: value
    }))
  }

  render() {
    const { paymentTypes, classes, onClose, selectedValue, ...other } = this.props;

    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          style={{ minHeight: "100%" }}
          {...other}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <Container>
            <Row>
              <Col xs="10" sm="10" md="11" lg="11" style={{ textAlign: "left" }}>
                <DialogTitle id="simple-dialog-title">
                  <span style={{ color: siteInstance.site?.btnBgColour }}>
                    <FormattedMessage id="pagamento.alterarTipoPagamento" />
                  </span>
                </DialogTitle>
              </Col>
              <Col xs="2" sm="2" md="1" lg="1"
                onClick={this.handleClose}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "right",
                  right: "10px"
                }}
              >
                <svg
                  width="33px"
                  height="33px"
                  viewBox="0 0 33 33"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Menu"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="MD"
                      transform="translate(-1311.000000, -21.000000)"
                      fill="#000000"
                    >
                      <g
                        id="close-(1)"
                        transform="translate(1311.000000, 21.000000)"
                      >
                        <path
                          d="M32.6096072,0.390441109 C32.0890191,-0.130147036 31.245005,-0.130147036 30.7244169,0.390441109 L0.390441109,30.7243524 C-0.130147036,31.2449405 -0.130147036,32.0889546 0.390441109,32.6095428 C0.650702954,32.8699335 0.991917965,33 1.33306852,33 C1.67421908,33 2.01536964,32.8698691 2.27569594,32.6094783 L32.6096072,2.27556703 C33.1301309,1.75504334 33.1301309,0.911029253 32.6096072,0.390441109 Z"
                          id="Path"
                        />
                        <path
                          d="M32.6095985,30.7243524 L2.27557092,0.390441109 C1.75504634,-0.130147036 0.910966357,-0.130147036 0.390441776,0.390441109 C-0.130147259,0.9109648 -0.130147259,1.75497888 0.390441776,2.27556703 L30.7244694,32.6095428 C30.9847317,32.8698691 31.3259472,33 31.6670984,33 C32.0082495,33 32.3494651,32.8698691 32.609663,32.6096072 C33.1301231,32.0889546 33.1301231,31.2449405 32.6095985,30.7243524 Z"
                          id="Path"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </Col>
              <Container>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      maxWidth={"sm"}
                    >
                      <SelectField
                        floatingLabelText={this.props.intl.formatMessage({
                          id: "pagamento.acao"
                        })}
                        value={this.state.paymentType.type}
                        onChange={this.handleChangePaymentType}
                        fullWidth={true}
                        style={{ textAlign: "center" }}
                        className="SelectField"
                      >
                        {paymentTypes.map((paymentType, j) => {
                          return paymentType.ID_TipoPagamento !== "NA" && paymentType.ID_TipoPagamento !== this.props.paymentType ? (
                            <MenuItem
                              value={paymentType.ID_TipoPagamento}
                              primaryText={paymentType.Descricao}
                            />
                          ) : null;
                        })}
                      </SelectField>
                      <SelectField hidden={!this.state.pagamentoEmParcelas}
                        floatingLabelText={
                          this.props.intl.formatMessage({
                            id: "pagamento.parcelas"
                          })
                        } fullWidth={true} style={{ textAlign: "center" }}
                        className="SelectField" onChange={this.handleChangeInstallments} value={this.state.parcelaEscolhida}
                      >
                        {this.state.parcelas.length > 0 ?
                          (this.state.parcelas.map(item => {
                            return <MenuItem
                              value={item.value}
                              primaryText={item.value + "" + ((item.value < 2) ? " Mês" : " Meses")}
                            />
                          }))
                          : (null)
                        }
                      </SelectField>


                      {/* Adicionar telemóvel MBWAY*/}
                      <div hidden={!this.state.pagamentoPorTelemovel} style={{ margin: "10px", padding: "10px", backgroundColor: "white", border: "1px solid grey", textAlign: "center" }}>
                        <span style={styleLabel}>
                          {this.props.intl.formatMessage({
                            id: "pagamento.telemovel"
                          })}
                        </span>

                        {/* Botão para adicionar um novo numero de telemovel*/}
                        <Row>
                          <Col>
                            <FlatButton
                              label={
                                <svg
                                  height="25px"
                                  viewBox="0 0 512 512"
                                  width="25px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                                  <path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
                                  <path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
                                </svg>
                              }
                              secondary={true}
                              onClick={this.clickToAddPhoneNumberForm}
                            />
                          </Col>
                        </Row>

                        <br />
                        {this.state.criarTelemovel !== true ? (
                          <Row>
                            <Col>
                              {this.state.telemoveis.length > 0 ? (
                                <SelectField
                                  floatingLabelText={this.props.intl.formatMessage({
                                    id: "pagamento.selecionarTelemoveis"
                                  })}
                                  value={this.state.numeroTelemovelSelecionado}
                                  onChange={this.handleChangePhoneNumber}
                                  fullWidth={true}
                                  style={{ fontWeight: "bold", textAlign: "center", paddingRight: "0px" }}
                                  className="SelectField"
                                  labelStyle={{ paddingRight: "0px" }}
                                >
                                  <MenuItem
                                    value={null} primaryText={"Selecione um Telemóvel"}
                                  />
                                  {this.state.telemoveis.map((tlm, j) => {
                                    return (
                                      <MenuItem
                                        value={tlm.Id}
                                        primaryText={tlm.Descricao}
                                      />
                                    );
                                  })}
                                </SelectField>
                              ) : null}
                            </Col>
                          </Row>
                        ) : (
                          <div>
                            <br />
                            <label>Adicionar Número de Telemóvel</label>
                            <Row>
                              <Col>
                                <PhoneInput searchPlaceholder="Procurar"
                                  placeholder="Introduzir Telemóvel" country="pt"
                                  copyNumbersOnly={false}
                                  enableSearch={true} onChange={this.handleChangeNewPhoneNumber}
                                />
                              </Col>
                            </Row>
                            <Button2
                              action={this.addNewPhoneNumber}
                              type="primary"
                              title={"Guardar Número de Telemóvel"}
                              style={{ "margin-top": "10px" }}
                            />
                            <Button2
                              action={this.clickToHidePhoneNumberForm}
                              title={"Cancelar"}
                              style={{ "margin-top": "10px", marginLeft: "5px" }}
                            />
                          </div>
                        )}


                      </div>

                    </FormControl>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                    <IHTPButton text={this.props.intl.formatMessage({ id: "pagamento.alterarTipoPagamento.alterarButton" })}
                      toUpper={true}
                      onClick={this.alterarTipoPagamento}
                      loading={this.state.loading} />
                  </Col>
                </Row>
              </Container>
            </Row>
            <br />
          </Container>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    paymentTypes: state.paymentTypes
  };
};

export default injectIntl(connect(mapStateToProps)(DialogChangePaymentMethod));
