import IhtpSite from "../classes/IhtpSite";
import { SiteFactoryClass } from "../classes/SiteFactory";

type Links = {
    storeUrl: string,
    mainSiteUrl: string,
    contactsUrl: string,
    resellUrl: string,
    crmUrl: string
}
    & (
        {
            hasBlog?: false
        }
        |
        {
            hasBlog: true,
            blogUrl: string
        }
    )
    & (
        {
            hasBeingConsultant?: false
        }
        |
        {
            hasBeingConsultant: true,
            beingConsultantUrl: string
        }
    )


const siteInstance = SiteFactoryClass.GetInstance();

class SiteLinksHelperClass {
    links: Links = { storeUrl: "", mainSiteUrl: "", contactsUrl: "", resellUrl: "", crmUrl: "" };

    public SetSitesUrlAccordingToLanguage(language: string) {
        this.SetStoreUrl(language);
        this.SetMainSiteUrl(language);
        this.SetContactsSiteUrl(language);
        this.SetResellSiteUrl(language);
        this.SetCrmSiteUrl();

        if (siteInstance.site === IhtpSite) {
            this.SetBlogSiteUrl(language);
            this.SetBeingConsultantSiteUrl(language);
        }
    }

    private SetStoreUrl(language: string) {
        const storePt = process.env.REACT_APP_MAIN_WEBSITE_SHOP_URL ?? "";
        const storeEn = process.env.REACT_APP_MAIN_WEBSITE_SHOP_URL_EN ?? "";
        if (language === "PT") {
            this.links.storeUrl = storePt;
        }
        else {
            this.links.storeUrl = storeEn;
        }
    }

    private SetMainSiteUrl(language: string) {
        const mainSitePt = process.env.REACT_APP_MAIN_WEBSITE_URL ?? "";
        const mainSiteEn = process.env.REACT_APP_MAIN_WEBSITE_URL_EN ?? "";
        if (language === "PT") {
            this.links.mainSiteUrl = mainSitePt;
        }
        else {
            this.links.mainSiteUrl = mainSiteEn;
        }
    }

    private SetContactsSiteUrl(language: string) {
        const mainSitePt = process.env.REACT_APP_MAIN_WEBSITE_URL ?? "";
        const mainSiteEn = process.env.REACT_APP_MAIN_WEBSITE_URL_EN ?? "";
        if (language === "PT") {
            this.links.contactsUrl = mainSitePt + "contactos";
        }
        else {
            this.links.contactsUrl = mainSiteEn + "contacts-en";
        }
    }

    private SetResellSiteUrl(language: string) {
        const revendaSitePt = process.env.REACT_APP_REVENDA_WEBSITE_URL ?? "";
        const revendaSiteEn = process.env.REACT_APP_REVENDA_WEBSITE_URL_EN ?? "";

        if (language === "PT") {
            this.links.resellUrl = revendaSitePt;
        }
        else {
            this.links.resellUrl = revendaSiteEn;
        }
    }

    private SetBlogSiteUrl(language: string) {
        const mainSitePt = process.env.REACT_APP_MAIN_WEBSITE_URL ?? "";
        const mainSiteEn = process.env.REACT_APP_MAIN_WEBSITE_URL_EN ?? "";

        let url = "";

        if (language === "PT") {
            url = mainSitePt + "contactos";
        }
        else {
            url = mainSiteEn + "contacts-en";
        }

        this.links = { ...this.links, hasBlog: true, blogUrl: url }
    }

    private SetBeingConsultantSiteUrl(language: string) {
        const mainSitePt = process.env.REACT_APP_MAIN_WEBSITE_URL ?? "";
        const mainSiteEn = process.env.REACT_APP_MAIN_WEBSITE_URL_EN ?? "";

        let url = "";

        if (language === "PT") {
            url = mainSitePt + "ser-consultor";
        }
        else {
            url = mainSiteEn + "being-a-consultant";
        }

        this.links = { ...this.links, hasBeingConsultant: true, beingConsultantUrl: url }
    }

    private SetCrmSiteUrl() {
        const crmSiteUrl = process.env.REACT_APP_MAIN_WEBSITE_URL ?? "";

        this.links.crmUrl = crmSiteUrl;
    }
}

const SiteLinksHelper = new SiteLinksHelperClass();
export default SiteLinksHelper;