
import { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { SiteFactoryClass } from "../../../../classes/SiteFactory";

export interface SidebarItemProps {
    page: string,
    itemName: string,
    label: string
}

const siteInstance = SiteFactoryClass.GetInstance();

export default function SidebarItem({ page, itemName, label }: SidebarItemProps) {
    const history = useHistory();
    const buttonRef = useRef<HTMLButtonElement>(null);

    const onClick = () => {
        try {
            const url = new URL(itemName);
            window.open(url.href);
        }
        //não é url
        catch (ex) {
            history.push("/" + itemName);
        }
    }

    useEffect(() => {
        const handleMouseOver = (): void => {
            if (buttonRef.current) {
                buttonRef.current.style.backgroundColor = siteInstance.site?.tableHeaderColour ?? "";
            }
        };

        const handleMouseOut = (): void => {
            if (buttonRef.current) {
                buttonRef.current.style.backgroundColor = page === itemName ? (siteInstance.site?.labelColour ?? "") : ""; // reset to default or original value
            }
        };

        if (buttonRef.current) {
            buttonRef.current.addEventListener('mouseover', handleMouseOver);
            buttonRef.current.addEventListener('mouseout', handleMouseOut);
        }

        return (): void => {
            if (buttonRef.current) {
                buttonRef.current.removeEventListener('mouseover', handleMouseOver);
                buttonRef.current.removeEventListener('mouseout', handleMouseOut);
            }
        };
    }, [buttonRef])

    return (
        <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
                <button ref={buttonRef}
                    onClick={onClick}
                    style={{
                        display: "block",
                        minWidth: "100%",
                        backgroundColor:
                            page === itemName
                                ? siteInstance.site?.labelColour
                                : "",
                        color: page === itemName ? "white" : ""
                    }}
                >
                    {label}
                </button>
            </Col>
        </Row>
    )
}