import { TextField } from "@material-ui/core";
import { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Input } from 'reactstrap';
import { getPaises } from '../../../../../../../actions/miniCartActions';
import { getCompany } from "../../../../../../../actions/myihtpActions";
import IHTPButton from "../../../../../../Common/IHTPButton";
import CriarEmpresa from './CriarEmpresa';

//Função para validar o NIF através do CheckSum
function validateNIF(value) {
    const nif = typeof value === "string" ? value : value.toString();
    const validationSets = {
        one: ["1", "2", "3", "5", "6", "8"],
        two: [
            "45",
            "70",
            "71",
            "72",
            "74",
            "75",
            "77",
            "79",
            "90",
            "91",
            "98",
            "99"
        ]
    };

    if (nif.length !== 9) {
        return false;
    }

    if (
        !validationSets.one.includes(nif.substr(0, 1)) &&
        !validationSets.two.includes(nif.substr(0, 2))
    ) {
        return false;
    }

    let total =
        nif[0] * 9 +
        nif[1] * 8 +
        nif[2] * 7 +
        nif[3] * 6 +
        nif[4] * 5 +
        nif[5] * 4 +
        nif[6] * 3 +
        nif[7] * 2;
    let modulo11 = Number(total) % 11;

    const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

    return checkDigit === Number(nif[8]);
}

let color = 'grey';
class VerificarEmpresaAtribuida extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nif: '',
            nifMessage: '',
            nifError: true,
            nifIsVerified: false,
            countries: [],
            country: 'PRT',
            countryName: 'Portugal',
            companyName: '',
            dialogIsOpen: false,
            companyAvailable: false,
        }

        this.handleInput = this.handleInput.bind(this);
        this.openCreateCompanyDialog = this.openCreateCompanyDialog.bind(this);
        this.closeCompanyDialog = this.closeCompanyDialog.bind(this);
        this.verifyNifIsAssociated = this.verifyNifIsAssociated.bind(this);
    };

    //Buscar os paises quando os componentes carregarem
    componentDidMount() {
        this.props.dispatch(getPaises())
            .then(result => {
                this.setState(
                    prevState => ({
                        ...prevState,
                        countries: result.data.paises
                    })
                );
            })
            .catch(err => {
            })
    }

    //Lidar com o input de texto
    handleInput(e) {

        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );

        if (name === 'nif') {
            this.setState(
                prevState => ({
                    ...prevState,
                    companyAvailable: false,
                    nifIsVerified: false,
                    nifError: true,
                    nifMessage: ''
                })
            );
        }

        if (name === 'country') {
            var tmpName = this.state.countries.find(country => country.alpha3 === value).name;
            this.setState(
                prevState => ({
                    ...prevState,
                    countryName: tmpName,
                    companyAvailable: false,
                    nifIsVerified: false,
                    nifError: true,
                    nifMessage: ''
                })
            );
        }
    }

    //Verificamos o nif 
    verifyNifIsAssociated() {

        //Ficamos com o state
        var state = { ...this.state };

        //Colocamos a informação que estamos a verificar o Nif
        this.setState(
            prevState => ({
                ...prevState,
                nifMessage: this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.verificarNif" }),
                nifError: true,
                nifIsVerified: false,
                companyAvailable: false
            })
        );

        //Se for portugal nif deve ter 9 dígitos
        if (state.country === 'PRT' && (state.nif.length !== 9 || !validateNIF(state.nif))) {
            this.setState(
                prevState => ({
                    ...prevState,
                    nifMessage: this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.nifInvalido" }),
                    nifError: true,
                    nifIsVerified: true,
                    companyAvailable: false
                })
            );
            return;
        }

        if (state.country !== 'PRT' && state.nif.length <= 0) {
            this.setState(
                prevState => ({
                    ...prevState,
                    nifMessage: this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.nifInvalido" }),
                    nifError: true,
                    nifIsVerified: true,
                    companyAvailable: false
                })
            );
            return;
        }

        //Validamos se a empresa existe
        getCompany(this.state.nif, this.state.country, true)
            .then(result => {

                //Se a resposta da API for bem sucedida
                if (result.success === true) {

                    //Se a lenght for maior que 0 significa que chegou a informação da Empresa
                    if (result.obj.length > 0) {

                        var clientType = this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.cliente" });
                        if (result.obj[0].TipoCliente == "CS") {
                            clientType = this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.consultor" });
                        }

                        this.setState(
                            prevState => ({
                                ...prevState,
                                nifMessage: `${this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.atribuida" })} ${clientType} ${result.obj[0].NomeCliente}`,
                                nifError: true,
                                nifIsVerified: true,
                                companyAvailable: false
                            })
                        );

                        //Significa que não se encontrou a empresa portanto esta pode ser usada
                    } else {
                        this.setState(
                            prevState => ({
                                ...prevState,
                                nifMessage: this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.validoParaUso" }),
                                nifError: false,
                                nifIsVerified: true,
                                companyAvailable: true,
                                companyName: result.obj2.NomeEmpresa
                            })
                        );
                    }
                } else {
                    this.setState(
                        prevState => ({
                            ...prevState,
                            nifMessage: result.message,
                            nifError: true,
                            nifIsVerified: true,
                            companyAvailable: false
                        })
                    );
                }
            })
            .catch(err => {
                this.setState(
                    prevState => ({
                        ...prevState,
                        nifMessage: this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.erro.ligacaoServidor" }),
                        nifError: true,
                        nifIsVerified: true,
                        companyAvailable: false
                    })
                );
            })
    }

    //Abrir o ecrã de criar empresa
    openCreateCompanyDialog() {
        this.setState(
            prevState => ({
                ...prevState,
                dialogIsOpen: true
            })
        );
    }

    //Fechar ecrã de criar empresa
    closeCompanyDialog() {
        this.setState(
            prevState => ({
                ...prevState,
                dialogIsOpen: false
            })
        );
    }
    render() {

        //Se o nif estiver verificado
        if (this.state.nifIsVerified) {
            color = (this.state.nifError === true) ? 'red' : 'green';
        } else {
            color = 'grey'
        }

        return (
            <div>

                <CriarEmpresa isOpen={this.state.dialogIsOpen}
                    nif={this.state.nif} name={this.state.companyName}
                    country={this.state.country} countryName={this.state.countryName}
                    toggle={this.closeCompanyDialog} />

                {/* Verificar se Empresa está atribuida a alguem*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b><FormattedMessage id="ferramentasUtilizador.empresaAtribuida.titulo" /></b>
                        </span>
                        <div className="form-group">
                            <br />

                            {/* Linha Input NIF */}
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "right" }} >
                                    <TextField
                                        placeholder={this.props.intl.formatMessage({
                                            id: "ferramentasUtilizador.empresaAtribuida.nifEmpresaPlaceholder"
                                        })}
                                        name={"nif"} id={"nif"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                                        type="text"
                                        fullWidth={true}
                                        onChange={this.handleInput}
                                        onBlur={this.verifyNifIsAssociated}
                                        value={this.state.nif}
                                    />
                                </Col>
                                <br />
                                <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "left" }} >
                                    <Input type="select" name="country" id="country" placeholder="País" style={{ maxWidth: isMobile ? "100%" : "50%" }} onChange={this.handleInput} value={this.state.country}>
                                        <option value="">{this.props.intl.formatMessage({
                                            id: "ferramentasUtilizador.empresaAtribuida.paisPlaceholder"
                                        })}</option>
                                        {this.state.countries && this.state.countries.length > 0 ?
                                            (this.state.countries.map((result, j) => {
                                                return (
                                                    <option value={result.alpha3}>
                                                        {result.name}
                                                    </option>
                                                );
                                            })
                                            ) : null}

                                    </Input>
                                </Col>
                            </Row>

                            <br />

                            {/* Linha Resposta API */}
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <label style={{ margin: "0px 5px 0px" }}><FormattedMessage id="ferramentasUtilizador.empresaAtribuida.resultado" />:</label><br />
                                    <label style={{ width: "100%", maxWidth: isMobile ? "100%" : "50%", border: `1px solid ${color}`, color: color, padding: "5px", marginTop: "5px" }}>
                                        <b>{(this.state.nifMessage.length <= 0) ? <FormattedMessage id="ferramentasUtilizador.empresaAtribuida.resultado" /> : this.state.nifMessage}</b></label>
                                </Col>
                            </Row>

                            <br />
                            <br />

                            {/* Botão para carregar */}
                            <Row>
                                <Col xs="12" sm="12" md="6" lg="6" style={{ textAlign: "right" }}>
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.verificarEmpresa" })}
                                        toUpper={true}
                                        style={{ minWidth: "20%" }}
                                        onClick={this.verifyNifIsAssociated} />
                                </Col>
                                <Col xs="12" sm="12" md="6" lg="6" style={{ textAlign: "left" }}>
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.criarEmpresa" })}
                                        toUpper={true}
                                        onClick={this.openCreateCompanyDialog}
                                        style={{ minWidth: "20%" }}
                                        disabled={!this.state.companyAvailable} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
    };
};

export default injectIntl(connect(mapStateToProps)(VerificarEmpresaAtribuida));