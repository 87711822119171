import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedDate, FormattedMessage } from "react-intl";
import { ToastContainer } from "react-toastr";
import {
  cancelSubscriptionsUser,
  getSubscriptionsUser
} from "../../../../../actions/myihtpActions";
import IHTPButton from "../../../../Common/IHTPButton";

let container;

var that;
class Subscricoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscricoes: []
    };
    that = this;
    that.cancelNotificacao = that.cancelNotificacao;
  }

  componentDidMount() {
    getSubscriptionsUser().then(data => {
      console.log(data);
      if (data.messageType === "success") {
        this.setState({ subscricoes: data.Subscriptions });
      }
    });
  }

  cancelNotificacao = id => {
    cancelSubscriptionsUser(id)
      .then(data => {
        console.log(data);
        if (data.messageType === "success") {
          getSubscriptionsUser().then(data => {
            if (data.messageType === "success") {
              this.setState({ subscricoes: data.Subscriptions });
              container.clear();
              container.success(`Subscrição cancelada com sucesso`);
            }
          });
        } else {
          container.clear();
          container.warning(`Erro ao cancelar subscrição`);
        }
      })
      .catch(err => {
        console.log(err);
        container.clear();
        container.warning(`Erro ao cancelar subscrição`);
      });
  };

  render() {
    return (
      <Row>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Col xs="12" sm="12" md="12" lg="12">
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              {this.state.subscricoes.map(function (item) {
                return (
                  <div>
                    <Row>
                      <Col xs="12" sm="12" md="7" lg="7">
                        <h5>{item.Descricao}</h5>
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="5"
                        lg="5"
                        style={{ textAlign: "right" }}
                      >
                        <FormattedMessage id="myihtp.notificacoes.subscrito" />{" "}
                        <FormattedDate value={new Date(item.DataInicio)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        style={{ textAlign: "right" }}
                      >
                        <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.notificacoes.removerSubscricao" })}
                          toUpper={true}
                          style={{ backgroundColor: "red" }}
                          onClick={() => that.cancelNotificacao(item.ID_Subscricao)} />
                      </Col>
                    </Row>
                    <hr />
                  </div>
                );
              })}
              <br />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
export default Subscricoes;
