import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { deletePrevisao, editMonthForecast, getCurrentMonthForecast } from "../../../../../../../actions/myihtpActions";
import Configs from "../../../../../../../helpers/Configs";
import IHTPButton from "../../../../../../Common/IHTPButton";
import DialogNovaPrevisao from "./DialogNovaPrevisao";

let container;

class ListaPrevisaoMesAtual extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      monthForecast: [],
      dadosPrevisao: {
        PrevisõesPessoais: "",
        PrevisõesKits: "",
        PrevisõesClientes: "",
        ID_Forecast: null
      },
      novaPrevisao: {
        VolumeClientes_P: "",
        VolumePessoal_P: "",
        VolumeKits_P: "",
        ano: "",
        mes: ""
      },
      ID_Forecast: null,
      expanded: {}
    };
    this.updateMonthForecast = this.updateMonthForecast;
    this.handleInput = this.handleInput.bind(this);
    this.handleAddInput = this.handleAddInput.bind(this);
  }

  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]]
      }
    });
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  updateMonthForecast = () => {
    getCurrentMonthForecast()
      .then(data => {
        if (data.success === true) {
          this.setState({ monthForecast: data.obj });
        }
      })
      .catch(() => {
        this.setState({ monthForecast: [] });
      });
  };

  componentDidMount() {
    getCurrentMonthForecast()
      .then(data => {
        if (data.success === true) {
          this.setState({ monthForecast: data.obj });
        }
      })
      .catch(() => {
        this.setState({ monthForecast: [] });
      });
  }

  changeForecastID = id => async e => {
    e.stopPropagation();
    this.setState({ ID_Forecast: id });
  };

  editMonthForecast = id => async e => {
    e.stopPropagation();
    editMonthForecast(this.state.dadosPrevisao, id)
      .then(response => {
        if (response.success === true) {
          getCurrentMonthForecast()
            .then(data => {
              if (data.success === true) {
                this.setState({ monthForecast: data.obj });
              }
            })
            .catch(() => {
              this.setState({ monthForecast: [] });
            });
          container.clear();
          container.success(response.message);
        } else {
          container.clear();
          container.error(response.message);
        }
      })
      .catch(error => {
        container.clear();
        container.error(<FormattedMessage id="myihtp.previsaoMesAtual.table.subcomponent.editarErro" />);
      });
  };

  handleAddInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(prevState => ({
      novaPrevisao: {
        ...prevState.novaPrevisao,
        [name]: value
      }
    }));
  }

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(prevState => ({
      dadosPrevisao: {
        ...prevState.dadosPrevisao,
        [name]: value
      }
    }));
  }

  render() {
    function BusinessCircleState(state) {
      if (state < 0.25) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#FF0000" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 0.25 && state < 0.5) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#FC8701" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 0.5 && state < 0.75) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#FCFF00" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 0.75 && state < 1) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#1600FF" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 1) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#01FF01" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      }
    }
    const { currencies } = this.props;
    const previsaomensalatual = {
      columns: [
        {
          Header: "ID_Forecast",
          accessor: "ID_Forecast",
          show: false,
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.previsaoMesAtual.table.Ano" />,
          accessor: "Ano",
          width: 50,
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.previsaoMesAtual.table.Mes" />,
          accessor: "Mes",
          width: 50,
          resizable: false
        },
        {
          Header: "Volume Pessoal",
          accessor: "VolumePessoal_P",
          show: false,
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VolumePessoal_P}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: "Volume Pessoal R",
          accessor: "VolumePessoal_R",
          show: false,
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VolumePessoal_R}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: "Volume Pessoal Percentagem Alcancada",
          accessor: "VolumePessoalPercentagemAlcancada",
          show: false,
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VolumePessoalPercentagemAlcancada}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesPessoais" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumePessoal_P}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumePessoal_R}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(
                        row.row.VolumePessoalPercentagemAlcancada
                      )}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumePessoalPercentagemAlcancada}
                        style="percent"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false
        },
        {
          Header: "Volume Kits",
          accessor: "VolumeKits_P",
          show: false,
          resizable: false
        },
        {
          Header: "Volume Kits R",
          accessor: "VolumeKits_R",
          show: false,
          resizable: false
        },
        {
          Header: "Volume Kits Percentagem Alcancada",
          accessor: "VolumeKitsPercentagemAlcancada",
          show: false,
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesKits" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeKits_P}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeKits_R}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(
                        row.row.VolumeKitsPercentagemAlcancada
                      )}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeKitsPercentagemAlcancada}
                        style="percent"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false
        },
        {
          Header: "Volume Clientes",
          accessor: "VolumeClientes_P",
          show: false,
          resizable: false
        },
        {
          Header: "Volume Clientes R",
          accessor: "VolumeClientes_R",
          show: false,
          resizable: false
        },
        {
          Header: "Volume Clientes Percentagem Alcancada",
          accessor: "VolumeClientesPercentagemAlcancada",
          show: false,
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesClientes" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeClientes_P}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeClientes_R}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(
                        row.row.VolumeClientesPercentagemAlcancada
                      )}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeClientesPercentagemAlcancada}
                        style="percent"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false
        },
        {
          Header: "Totais",
          accessor: "Totais_P",
          show: false,
          resizable: false
        },
        {
          Header: "Totais R",
          accessor: "Totais_R",
          show: false,
          resizable: false
        },
        {
          Header: "Total Percentagem Alcancada",
          accessor: "TotalPercentagemAlcancada",
          show: false,
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.Totais" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.Totais_P}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.Totais_R}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(row.row.TotalPercentagemAlcancada)}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.TotalPercentagemAlcancada}
                        style="percent"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false
        },
        {
          Header: "",
          accessor: "totais",
          width: 50,
          Cell: row => {
            return (
              <div>
                <a>
                  <svg
                    height="15px"
                    viewBox="0 0 512 512.00115"
                    width="15px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
                    <path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
                  </svg>
                </a>
              </div>
            );
          },
          resizable: false
        }
      ]
    };
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Row style={{ textAlign: "center" }}>
          <Col xs="12" sm="12" md="12" lg="12">
            <b>
              <FormattedMessage id="myihtp.previsaoMesAtual.title" />
            </b>
            <Button color="primary" onClick={this.handleClickOpen}>
              <svg
                height="25px"
                viewBox="0 0 512 512"
                width="25px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                <path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
                <path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
              </svg>
            </Button>{" "}
          </Col>
        </Row>

        <DialogNovaPrevisao
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
          updateMonthForecastFunction={this.updateMonthForecast}
        />
        <ReactTable
          className="-highlight  tableReact"
          data={this.state.monthForecast}
          expanded={this.state.expanded}
          onExpandedChange={(newExpanded, index, event) =>
            this.handleRowExpanded(newExpanded, index, event)
          }
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                "align-items": "center"
              }
            };
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.id === "totais") {
                  var confirm = window.confirm(this.props.intl.formatMessage({ id: "myihtp.previsaoMesAtual.table.subcomponent.removerPrevisao" }));
                  if (confirm) {

                    deletePrevisao(rowInfo.original.ID_Forecast)
                      .then(response => {
                        if (response.success === true) {
                          getCurrentMonthForecast()
                            .then(data => {
                              if (data.success === true) {
                                this.setState({ monthForecast: data.obj });
                                container.clear();
                                container.success(response.message);
                              }
                            })
                            .catch(() => {
                              this.setState({ monthForecast: [] });
                            });
                        } else {
                          container.clear();
                          container.error(response.message);
                        }
                      }).catch(err => {
                        container.clear();
                        container.error(<FormattedMessage id="myihtp.previsaoMesAtual.table.subcomponent.removerErro" />);
                      });
                  }
                } else {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                }
              },
              style: {
                top: "0px",
                bottom: "0px",
                margin: "auto"
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.monthForecast.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
          columns={previsaomensalatual.columns}
          SubComponent={row => {
            return (
              <Container>
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <Row style={{ alignItems: "center" }}>
                      <Col
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        style={{ textAlign: "center" }}
                      >
                        <TextField
                          id="PrevisõesPessoais"
                          type="number"
                          label={
                            <FormattedMessage id="myihtp.previsaoMesAtual.table.subcomponent.PrevisõesPessoais" />
                          }
                          name="PrevisõesPessoais"
                          onChange={this.handleInput}
                          margin="normal"
                          variant="outlined"
                          style={{ fontSize: "12px" }}
                        />
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        style={{ textAlign: "center" }}
                      >
                        <TextField
                          id="PrevisõesKits"
                          type="number"
                          label={
                            <FormattedMessage id="myihtp.previsaoMesAtual.table.subcomponent.PrevisõesKits" />
                          }
                          name="PrevisõesKits"
                          onChange={this.handleInput}
                          margin="normal"
                          variant="outlined"
                          style={{ fontSize: "12px" }}
                        />
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        style={{ textAlign: "center" }}
                      >
                        <TextField
                          id="PrevisõesClientes"
                          type="number"
                          label={
                            <FormattedMessage id="myihtp.previsaoMesAtual.table.subcomponent.PrevisõesClientes" />
                          }
                          onChange={this.handleInput}
                          name="PrevisõesClientes"
                          margin="normal"
                          variant="outlined"
                          style={{ fontSize: "12px" }}
                        />
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        style={{ textAlign: "center" }}>

                        <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.previsaoMesAtual.table.subcomponent.button.gravar" })}
                          toUpper={true}
                          onClick={this.editMonthForecast(row.original.ID_Forecast)} />

                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            );
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currencies: state.currencies
  };
};

export default injectIntl(connect(mapStateToProps)(ListaPrevisaoMesAtual));
