// Dados da primeira página
import { isMobile } from "react-device-detect";
import { FormattedDate, FormattedMessage } from "react-intl";
import { getSite } from "../actions/geralActions";
import { SiteFactoryClass } from "../classes/SiteFactory";
import DashboardConfigs from "./ConfigsMyIHTP/DashboardConfigs";
import IndicadoresConfig from "./ConfigsMyIHTP/IndicadoresConfig";
import InformacoesPessoaisConfig from "./ConfigsMyIHTP/InformacoesPessoaisConfig";
import Routes from "./Routes";

const siteInstance = SiteFactoryClass.GetInstance();

class Configs {
  constructor() {
    getSite().then((result) => {
      siteInstance.SetSite(result.toLowerCase())
      // Popular dados da primeira página
      this.landingPage = {
        speedForSuccess: {
          title: <FormattedMessage id="home.speedForSuccess.title" />,
          steps: [
            {
              id: "step1",
              title: "step1",
              imgSrc:
                "https://doremon.ihavethepower.net/assets/images/homepage/speedForSuccess/step1.png",
              url: Routes.account.login
            },
            {
              id: "step2",
              title: "step2",
              imgSrc:
                "https://doremon.ihavethepower.net/assets/images/homepage/speedForSuccess/step2.png",
              url: Routes.account.login
            },
            {
              id: "step3",
              title: "step3",
              imgSrc:
                "https://doremon.ihavethepower.net/assets/images/homepage/speedForSuccess/step3.png",
              url: Routes.account.login
            },
            {
              id: "step4",
              title: "step4",
              imgSrc:
                "https://doremon.ihavethepower.net/assets/images/homepage/speedForSuccess/step4.png",
              url: Routes.account.login
            }
          ]
        },
        academyCarrosselSettings: {
          mouseDragEnable: false,
          buttonsDisabled: true,
          dotsDisabled: false,
          keysControlDisabled: false,
          autoPlay: true
        },
        AcademyDescriptionStyle: {
          display: "flex",
          textAlign: "justify",
          alignItems: "center",
          lineHeight: "30px"
        },
        AcademyTitle: <FormattedMessage id="home.asnossasacademias.title" />,
        AcademyProductTitleStyle: {
          fontSize: "25px",
          color: "rgb(202, 0, 106)"
        },
        AcademyProductDescriptionStyle: {
          textAlign: "justify",
          lineHeight: "30px",
          fontSize: "15px",
          height: "150px",
          minHeight: "100%",
          display: "contents"
        },
        academy: {
          personalExcellence: {
            id: "personalExcellence",
            title: "",
            description:
              "Ser Feliz é o propósito de qualquer ser humano e isso pode ser treinado, desenvolvido e partilhado. A oferta que temos nesta Academia combina a Sabedoria milenar de grandes inspiradores da humanidade, a tecnologia de treino mental da PNL (programação neuro-linguística) e a experiência dos nossos Trainers que aplicam o que ensinam.",
            autoPlayInterval: 2500,
            productsCarroussel: [
              {
                title: "GPS | Guie-se pelos sonhos",
                description:
                  "GPS (Guie-se Pelos Sonhos) é o curso I Have the Power inicial para treinar pessoas para o êxito. Trabalhando o CPS (Condicionamento para o Sucesso), uma metodologia criada pelo Dr. Adelino Cunha, você aprenderá neste curso a preparar a sua mente e a descobrir os seus sonhos (As SEMENTES), que depois de lançadas a uma terra preparada, garantidamente darão muitos e bonitos frutos na sua vida e na de todos os que a(o) venham a conhecer.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/images/courses/PersonalExcellenceAcademy/gps.png",
                url: "/shop/product-details/3300"
              },
              {
                title: "ALTO DESEMPENHO COM PNL",
                description:
                  "Neste curso você vai aprender a trabalhar a motivação, a auto-imagem, a auto-estima, a auto-confiança, como transformar e reforçar crenças, como definir visão/missão/valores, como lidar com medos e fobias, e como desenvolver relacionamentos poderosos.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/images/courses/PersonalExcellenceAcademy/adpnl.png",
                url: "/shop/product-details/3300"
              },
              {
                title: "IHTP LICENSED PRACTITIONER",
                description:
                  "Neste curso vai ter ao seu dispor o estilo, as técnicas, o conhecimento, o humor e toda a capacidade, que o vai ajudar a praticar e aproveitar de um curso fantástico, em que, divertindo-se, vai aprender as ferramentas que o farão um praticante (Practitioner) certificado internacionalmente em PNL. No final, o seu certificado será emitido e assinado pelo próprio Dr. Richard Bandler, o co-criador da PNL, e dar-lhe-á acesso a outros níveis de certificação internacional nesta área fantástica e em rápido crescimento a nível mundial que é a PNL.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/images/courses/PersonalExcellenceAcademy/nlp-practitioner.png",
                url: "/shop/product-details/3300"
              },
              {
                title: "IHTP LICENSED MASTER",
                description:
                  "Neste curso vai ter ao seu dispor o estilo, as técnicas, o conhecimento, o humor e toda a capacidade, que o vai ajudar a praticar e aproveitar de um curso fantástico, em que, divertindo-se, vai aprender as ferramentas que o farão um praticante (Practitioner) certificado internacionalmente em PNL. No final, o seu certificado será emitido e assinado pelo próprio Dr. Richard Bandler, o co-criador da PNL, e dar-lhe-á acesso a outros níveis de certificação internacional nesta área fantástica e em rápido crescimento a nível mundial que é a PNL.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/images/courses/PersonalExcellenceAcademy/nlp-master.png",
                url: "/shop/product-details/3300"
              },
              {
                title: "IHTP PERSONAL FINANCES",
                description:
                  "Este é um curso onde vai aprender a gerir melhor as suas fontes de rendimento e onde vai praticar as atitudes de quem tem riqueza com crescimento sustentado. Neste curso vai aprender um vasto leque de ferramentas para poupar mais e livrar-se das “más” dívidas e conhecer diferentes meios para investir os seus activos, amplificando a sua rentabilidade.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/images/courses/PersonalExcellenceAcademy/personalFinances.png",
                url:
                  "https://www.ihavethepower.net/Produto/1864/ihtp-personal-finances---gestao-de-financas-pessoais-ao-vivo---video-online"
              },
              {
                title: "IHTP ALIGNMENT",
                description:
                  "Neste curso vai clarificar o que nasceu para ser, fazer e ter, quem é e quais os seus sonhos e projectos actuais, comparando-os com os seus talentos mais profundos, e poder criar os planos de ação para se alinhar aumentando os seus níveis de congruência pessoal e profissional, através da congruência entre a sua mente consciente, subconsciente e as suas ações diárias.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/images/courses/PersonalExcellenceAcademy/alignment.png",
                url:
                  "https://www.ihavethepower.net/Produto/1271/alignment---alinhe-se-com-o-melhor-de-si-ao-vivo---video-online"
              }
            ]
          },
          wellness: {
            id: "wellness",
            title: "",
            description:
              "A saúde, o equilíbrio e o bem estar são bens essenciais da humanidade. A consciencialização do corpo , a percepção da identidade, do seu biorritmo e o cuidado com o mesmo pode ser desenvolvido e treinado .A oferta desta academia é feita através de sistemas de treino com base na PNL ( Programação Neurolinguistica ) e nas metodologias do Coaching , assim como produtos e serviços que permitam resultados que perdurem no tempo sem descurar os profissionais do sector munindo-os de ferramentas para se tornarem cada vez melhores .",
            autoPlayInterval: 3000,
            productsCarroussel: [
              {
                title: "IHTP SEGREDOS DA COZINHA VEGETARIANA",
                description:
                  "Nunca se falou tanto de dietas vegetarianas como agora. A verdade é que esta dieta tem vantagens e desvantagens para o ser humano. Neste curso vai aprender todos os segredos da dieta vegetariana, aprendendo a confeccionar alguns dos pratos vegetarianos mais nutritivos e saudáveis.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/uploads/e5ad97d5-332d-4d62-89e0-e0c1fd79ae8b.jpg",
                url: "/shop/product-details/3300"
              },
              {
                title: "WONDERLIVES - Suplementos Alimentares",
                description:
                  "Esta é a nossa linha de suplementos alimentares formulada especificamente para o equilíbrio e excelência da sua Saúde e Bem Estar.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/uploads/e5ad97d5-332d-4d62-89e0-e0c1fd79ae8b.jpg",
                url: "/shop/product-details/3300"
              }
            ]
          },
          sports: {
            id: "sports",
            title: "",
            description:
              "Muito mais do que o apenas “vai correr tudo bem”, com as metodologias e ferramentas disponíveis, os atletas, praticantes e demais agentes desportivos terão apoio nas áreas do treino mental e estratégias mentais, da motivação, do foco e do controlo emocional, atingindo os objetivos e, até, resultados que não acreditavam serem possíveis.",
            autoPlayInterval: 2300,
            productsCarroussel: [
              {
                title: "IHTP Alto Desempenho no Fitness",
                description: "",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/uploads/e5ad97d5-332d-4d62-89e0-e0c1fd79ae8b.jpg",
                url: "/shop/product-details/3300"
              },
              {
                title: "IHTP Alto Desempenho no Fitness",
                description: "",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/uploads/e5ad97d5-332d-4d62-89e0-e0c1fd79ae8b.jpg",
                url: "/shop/product-details/3300"
              }
            ]
          },
          leadership: {
            id: "leadership",
            title: "",
            description:
              "A capacidade de se tornar um melhor Líder, depende da forma como pensa, comunica e age. Na Academia de Liderança queremos, através das melhores ferramentas de PNL e Coaching ajudar a construir exemplos humanos, que mostrem que é possível liderar organizações, projetos, pessoas e a própria vida pessoal através do exemplo e da aplicação dos valores, obtendo os resultados pretendidos.",
            autoPlayInterval: 3400,
            productsCarroussel: [
              {
                title: "GPS | Guie-se pelos sonhos",
                description:
                  "Lorem ipsum dolor sit sdfsdfs consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/uploads/e5ad97d5-332d-4d62-89e0-e0c1fd79ae8b.jpg",
                url: "/shop/product-details/3300"
              }
            ]
          },
          businessDevelopment: {
            id: "businessDevelopment",
            title: "",
            description:
              "Nesta academia ajudamos a converter sonhos em realidade desenvolvendo o espírito empreendedor que vive dentro de nós. Ajudamos a desenvolver START UP's, a expandir EMPRESAS, treinando equipas de VENDAS e EMPRESÁRIOS para que com melhores pessoas e maior competência possam gerar resultados extraordinários.",
            autoPlayInterval: 2800,
            productsCarroussel: [
              {
                title: "GPS | Guie-se pelos sonhos",
                description:
                  "Lorem ipsum dolor sit sdfsdfs consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/uploads/e5ad97d5-332d-4d62-89e0-e0c1fd79ae8b.jpg",
                url: "/shop/product-details/3300"
              }
            ]
          },
          hrCoaching: {
            id: "hrCoaching",
            title: "",
            description:
              "Esta Academia fornece serviços dedicados à gestão integrada de RH, formação internacional em Coaching e serviços de Coaching prestados por profissionais internacionalmente certificados, que permitem que, do estudante ao empresário, do profissional liberal à família, do director ou administrador ao operador de linha, todos podem beneficiar de um processo de coaching.",
            autoPlayInterval: 3600,
            productsCarroussel: [
              {
                title: "GPS | Guie-se pelos sonhos",
                description:
                  "Lorem ipsum dolor sit sdfsdfs consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/uploads/e5ad97d5-332d-4d62-89e0-e0c1fd79ae8b.jpg",
                url: "/shop/product-details/3300"
              }
            ]
          },
          education: {
            id: "education",
            title: "",
            description:
              "Educar é um dos papéis mais nobres que o ser humano pode ter, contribuíndo para tocar vidas, abrir mentes e guiar corações. Com o propósito de garantir um mundo melhor, amanhã, a Academia de Educação inspira-se nas melhores metodologias de Coaching, nas ferramentas mais atuais da PNL (Programação Neuro-linguística) e na experiência acomulada dos seus profissionais para entregar soluções personalizadas a cada família ou comunidade educativa.",
            autoPlayInterval: 2500,
            productsCarroussel: [
              {
                title: "GPS | Guie-se pelos sonhos",
                description:
                  "Lorem ipsum dolor sit sdfsdfs consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                imageSrc:
                  "https://doremon.ihavethepower.net/assets/uploads/e5ad97d5-332d-4d62-89e0-e0c1fd79ae8b.jpg",
                url: "/shop/product-details/3300"
              }
            ]
          }
        },
        particularOrEnterprise: {
          title: "Para si ou para a sua empresa",
          particular: {
            imgSrc:
              "https://doremon.ihavethepower.net/assets/images/homepage/particularOrEnterprise/particular.png",
            url: "/Loja/Catalogo/CS"
          },
          enterprise: {
            imgSrc:
              "https://doremon.ihavethepower.net/assets/images/homepage/particularOrEnterprise/business.png",
            url: "/Loja/Catalogo/IH"
          }
        }
      };

      // Popular dados do myIHTP
      this.myIHTP = {
        informacaoBase: DashboardConfigs.informacoesBase,
        tableConfig: {
          fontHeightHeader: "bold",
          backgroundColorHeader: siteInstance.site?.tableHeaderColour,
          colorHeader: "white",
          heightHeader: "35px",
          fontSizeHeader: "14px",
          fontSizeRowBody: "12px",
          encomendas: {
            data: {
              route: "/encomendas/getAllOrders",
              method: "POST"
            },
            columns: {
              idEncomenda: {
                Header: <FormattedMessage id="myihtp.encomendas.ID_Encomenda" />,
                accessor: "ID_Encomenda",
                resizable: false
              },
              idCliente: {
                Header: <FormattedMessage id="myihtp.encomendas.ID_Cliente" />,
                accessor: "ID_Cliente",
                show: false,
                resizable: false
              },
              idEstadoEncomenda: {
                Header: (
                  <FormattedMessage id="myihtp.encomendas.ID_EstadoEncomenda" />
                ),
                accessor: "ID_EstadoEncomenda",
                show: false,
                resizable: false
              },
              estadoEncomenda: {
                Header: (
                  <FormattedMessage id="myihtp.encomendas.EstadoEncomenda" />
                ),
                accessor: "EstadoEncomenda",
                resizable: false
              },
              dataEncomenda: {
                Header: <FormattedMessage id="myihtp.encomendas.DataEncomenda" />,
                accessor: "DataEncomenda",
                resizable: false
              },
              precoFinal: {
                Header: <FormattedMessage id="myihtp.encomendas.PrecoFinal" />,
                accessor: "PrecoFinal",
                resizable: false
              },
              UrlFatura: {
                Header: "",
                accessor: "UrlFatura",
                resizable: false
              }
            },
            orderDetails: {
              title: "Detalhe de encomenda",
              styleTitle: {
                fontSize: "22px",
                textAlign: "center",
                backgroundColor: "rgba(27, 114, 181, 0.81)",
                color: "rgba(255, 255, 255, 1)"
              },
              styleSubtitle: {
                fontSize: "18px",
                textAlign: "center",
                backgroundColor: "#ffe4c4",
                color: "black"
              },
              styleData: {
                fontSize: "16px",
                textAlign: "center"
              },
              styleData2: {
                fontSize: "16px",
                textAlign: isMobile ? "center" : "left"
              }
            },
            itensDetails: {
              title: "Itens de encomenda",
              styleTitle: {
                fontSize: "22px",
                textAlign: "center",
                backgroundColor: "rgba(27, 114, 181, 0.81)",
                color: "rgba(255, 255, 255, 1)"
              },
              styleTable: {
                backgroundColor: "#ffe4c4",
                color: "black"
              },
              columns: {
                nome: {
                  header: "Nome",
                  accessor: "nome",
                  resizable: false
                },
                iva: {
                  header: "Iva",
                  accessor: "valorivacf",
                  resizable: false,
                  width: 100
                },
                quantidade: {
                  header: "Quantidade",
                  accessor: "Quantidade",
                  resizable: false,
                  width: 100
                },
                precoUnitario: {
                  header: "Preço Unitário",
                  accessor: "precoclientefinalcomiva",
                  resizable: false,
                  width: 100
                }
              }
            },
            changeOrderState: {
              title: "Evolução de encomenda",
              styleTitle: {
                fontSize: "22px",
                textAlign: "center",
                backgroundColor: "rgba(27, 114, 181, 0.81)",
                color: "rgba(255, 255, 255, 1)"
              },
              styleTable: {
                backgroundColor: "#ffe4c4",
                color: "black"
              },
              columns: {
                data: {
                  header: "Data",
                  accessor: "Data",
                  resizable: false
                },
                estadoEncomenda: {
                  header: "Estado de Encomenda",
                  accessor: "EstadoEncomenda",
                  resizable: false
                },
                observacao: {
                  header: "Observação",
                  accessor: "Observacao",
                  resizable: false
                },
                emailEnviado: {
                  header: "Email enviado",
                  accessor: "EnviadoMail",
                  resizable: false
                }
              }
            },
            backgroundColor: {
              green: "rgb(202, 255, 202, 0.6)",
              yellow: "rgba(255, 250, 174, 0.6)",
              red: "rgb(252, 178, 184, 0.6)",
              cyan: "rgba(0, 230, 230, 0.36)"
            }
          },
          ocorrencias: DashboardConfigs.ocorrencias,
          previsaomensalatual: DashboardConfigs.previsaomensalatual,
          comparativomensaldashboard: DashboardConfigs.comparativomensal,
          comparativoanualdashboard: DashboardConfigs.comparativoanual,
          agendamento: DashboardConfigs.agendamento,
          aniversarios: DashboardConfigs.aniversarios,
          listaregistosmaisrecentes: DashboardConfigs.listaregistosmaisrecentes,
          ultimasencomendas: DashboardConfigs.ultimasencomendas,
          orderDetails: DashboardConfigs.orderDetails,
          itensDetails: DashboardConfigs.itensDetails,
          changeOrderState: DashboardConfigs.changeOrderState,
          proximoscursos: DashboardConfigs.proximoscursos,
          proximoscursosmanagers: DashboardConfigs.proximoscursosmanagers,
          ultimasinscricoes: DashboardConfigs.ultimasinscricoes,
          comparativomensalindicadores: IndicadoresConfig.comparativomensal,
          comparativoanualindicadores: IndicadoresConfig.comparativoanual,
          faturacaomescorrenteindicadores: IndicadoresConfig.faturacaomescorrente,
          faturacaoacumuladaindicadores: IndicadoresConfig.faturacaoacumulada,
          faturacao1trimestreindicadores: IndicadoresConfig.faturacao1trimestre,
          niveisnegocioindicadores: IndicadoresConfig.niveisnegocio,
          rentabilidademesindicadores: IndicadoresConfig.rentabilidademes,
          consumopessoalmesindicadores: IndicadoresConfig.consumopessoalmes,
          recomendacaoindicadores: IndicadoresConfig.recomendacao,
          volumeacumuladomesindicadores: IndicadoresConfig.volumeacumuladomes,
          rentabilidadeatehojeindicadores: IndicadoresConfig.rentabilidadeatehoje,
          contactosinfopessoais: InformacoesPessoaisConfig.contactos,
          moradasinfopessoais: InformacoesPessoaisConfig.moradas,
          protocolos: {
            columns: [
              {
                Header: "Detalhe",
                accessor: "Detalhe",
                resizable: false
              },
              {
                Header: "Protocolo",
                accessor: "Protocolo",
                resizable: false
              },
              {
                Header: "Nome Consultor",
                accessor: "NomeConsultor",
                resizable: false
              },
              {
                Header: "Cliente Afiliado",
                accessor: "ClienteAfiliado",
                resizable: false
              },
              {
                Header: "Data",
                accessor: "Data",
                resizable: false
              },
              {
                Header: "Clientes Protocolados",
                accessor: "ClientesProtocolados",
                resizable: false
              }
            ]
          },
          recomendacoes: {
            columns: [
              {
                Header: "Nome",
                accessor: "name",
                resizable: false
              },
              {
                Header: "E-mail",
                accessor: "email",
                resizable: false
              },
              {
                Header: "Data de Envio",
                accessor: "sendDate",
                resizable: false
              },
              {
                Header: "Data de Registo no Site",
                accessor: "registerDate",
                resizable: false
              }
            ]
          },
          cursos: {
            columns: [
              {
                Header: <FormattedMessage id="myihtp.cursos.title" />,
                accessor: "Descricao",
                resizable: false,
                Cell: row => {
                  return (
                    <span
                      className="fake-link"
                      id="fake-link-1"
                      onClick={() => {
                        const id = row.original.ID_Produto
                          ? row.original.ID_Produto
                          : row.original.ID_Pack;
                        window.open(Routes.redirectToProduct(id), "_blank");
                      }}
                    >
                      {row.original.Descricao}
                    </span>
                  );
                }
              }
            ]
          },
          meusprodutos: {
            columns: [
              {
                Header: <FormattedMessage id="myihtp.produto.title" />,
                accessor: "Descricao",
                resizable: false,
                Cell: row => {
                  return (
                    <span
                      className="fake-link"
                      id="fake-link-1"
                      onClick={() => {
                        const id = row.original.ID_Produto
                          ? row.original.ID_Produto
                          : row.original.ID_Pack;
                        window.open(Routes.redirectToProduct(id), "_blank");
                      }}
                    >
                      {row.original.Descricao}
                    </span>
                  );
                }
              }
            ]
          },
          produtosrevenda: {
            columns: [
              {
                Header: <FormattedMessage id="myihtp.produto.title" />,
                accessor: "Descricao",
                resizable: false,
                Cell: row => {
                  return (
                    <span
                      className="fake-link"
                      id="fake-link-1"
                      onClick={() => {
                        const id = row.original.ID_Produto
                          ? row.original.ID_Produto
                          : row.original.ID_Pack;
                        window.open(Routes.redirectToProduct(id), "_blank");
                      }}
                    >
                      {row.original.Descricao}
                    </span>
                  );
                }
              }
            ]
          },
          subscricoes: {
            columns: [
              {
                Header: <FormattedMessage id="myihtp.subscricoes.subscricao" />,
                accessor: "subscricao",
                resizable: false,
                Cell: row => {
                  return (
                    <span
                      className="fake-link"
                      id="fake-link-1"
                      onClick={() => {
                        const id = row.original.ID_Produto
                          ? row.original.ID_Produto
                          : row.original.ID_Pack;
                        window.open(Routes.redirectToProduct(id), "_blank");
                      }}
                    >
                      {row.original.subscricao}
                    </span>
                  );
                }
              },
              {
                Header: <FormattedMessage id="myihtp.subscricoes.periocidade" />,
                accessor: "Periodicidade",
                resizable: false,
                width: 100
              },
              {
                Header: <FormattedMessage id="myihtp.subscricoes.datainicio" />,
                accessor: "DataInicio",
                resizable: false,
                width: 100,
                Cell: row => {
                  return (
                    <FormattedDate value={new Date(row.original.DataInicio)} />
                  );
                }
              },
              {
                Header: <FormattedMessage id="myihtp.subscricoes.datafim" />,
                accessor: "DataFim",
                resizable: false,
                width: 100,
                Cell: row => {
                  return <FormattedDate value={new Date(row.original.DataFim)} />;
                }
              },
              {
                Header: (
                  <FormattedMessage id="myihtp.subscricoes.datacancelamento" />
                ),
                accessor: "DataCancelamento",
                resizable: false,
                width: 100,
                Cell: row => {
                  return row.original.DataCancelamento ? (
                    <FormattedDate
                      value={new Date(row.original.DataCancelamento)}
                    />
                  ) : null;
                }
              },
              {
                Header: "",
                accessor: "totais",
                width: 50,
                Cell: row => {
                  if (row.row.DataCancelamento) {
                    return <div />;
                  } else {
                    return (
                      <div>
                        <a>
                          <svg
                            height="15px"
                            viewBox="0 0 512 512.00115"
                            width="15px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m485.191406 26.8125c-35.738281-35.742188-93.929687-35.757812-129.683594 0l-324.195312 324.199219c-.082031.085937-.105469.203125-.191406.289062-1.476563 1.53125-2.632813 3.316407-3.503906 5.28125-.226563.511719-.355469 1.015625-.535157 1.539063-.257812.757812-.625 1.460937-.78125 2.257812l-25.941406 129.679688c-1.207031 6.019531.679687 12.234375 5.015625 16.570312 3.476562 3.472656 8.148438 5.371094 12.964844 5.371094 1.199218 0 2.402344-.117188 3.601562-.355469l129.683594-25.941406c.792969-.160156 1.5-.527344 2.253906-.78125.523438-.179687 1.03125-.308594 1.539063-.535156 1.964843-.875 3.75-2.03125 5.28125-3.503907.085937-.085937.203125-.109374.289062-.195312l324.199219-324.191406c35.75-35.757813 35.75-93.9375.003906-129.683594zm-337.167968 414.972656-77.808594-77.808594 272.320312-272.328124 77.816406 77.816406zm-106.304688 28.5 13.824219-69.105468 55.28125 55.277343zm417.539062-339.726562-12.972656 12.972656-77.816406-77.816406 12.972656-12.972656c21.453125-21.472657 56.359375-21.4375 77.816406 0 21.445313 21.457031 21.445313 56.363281 0 77.816406zm0 0" />
                            <path d="m148.023438 382.320312c-4.691407 0-9.382813-1.789062-12.964844-5.375-7.164063-7.164062-7.164063-18.769531 0-25.929687l194.515625-194.519531c7.164062-7.164063 18.773437-7.164063 25.933593 0 7.160157 7.164062 7.164063 18.769531 0 25.933594l-194.519531 194.515624c-3.582031 3.582032-8.273437 5.375-12.964843 5.375zm0 0" />
                          </svg>
                        </a>
                      </div>
                    );
                  }
                },
                resizable: false
              }
            ]
          },
          collapseOnDataChange: false,
          defaultPageSize: 5,
          showPagination: true,
          buttonCopyAnterior: (
            <FormattedMessage id="table.config.buttonCopyAnterior" />
          ),
          buttonCopyProximo: (
            <FormattedMessage id="table.config.buttonCopyProximo" />
          ),
          noDataText: <FormattedMessage id="table.config.noDataText" />,
          pageText: <FormattedMessage id="table.config.pageText" />,
          ofText: <FormattedMessage id="table.config.ofText" />,
          rowsText: "linhas"
        }
      };
    })
  }
}

var ConfigsObject = new Configs();

export default ConfigsObject;
