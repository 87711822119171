import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { SiteFactoryClass } from "../../../../../classes/SiteFactory";

const siteInstance = SiteFactoryClass.GetInstance();

class Subtitle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row
        style={{
          marginBottom: this.props.marginBottom,
          marginTop: this.props.marginTop
        }}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            backgroundColor: siteInstance.site?.labelColour
          }}
        >
          <span
            style={{
              color: "white",
              top: "0px",
              bottom: "0px",
              margin: "auto",
              fontSize: isMobile ? "15px" : "20px"
            }}
          >
            {this.props.text}
          </span>
        </Col>
      </Row>
    );
  }
}

export default Subtitle;
