import SiteClass, { SiteClassProps } from "./Site";
import TawkToInfo from "./TawkToInfo";

class WonderlivesSiteClass extends SiteClass {
    constructor() {
        const props: SiteClassProps = {
            urlBase: "https://wonderlives.com",
            faviconName: "/ihtp-wonder-lives.ico",
            btnBgColour: "#034275",
            siteName: "Wonderlives",
            headerColour: "#CC528C",
            labelColour: "#CC528C",
            loadingImage: "/assets/images/ihtp-wonder-lives-loading.png",
            logo: "/assets/images/ihtp-wonder-lives.svg",
            email: "wonderlives@solfut.com",
            hasFacebookLogin: false,
            hasGoogleLogin: false,
            hasSocialMedia: false,
        }

        super(props);

        this.tawkToInfo = new TawkToInfo("66f691124cbc4814f7dfe926");
        this.tawkToInfo.Add("pt", "1i8pidc61");
    }
}

const WonderlivesSite = new WonderlivesSiteClass();
export default WonderlivesSite;